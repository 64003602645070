// If in a separate file, ensure you import this in your component or its parent
.cropped-images {
    max-width: 70%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); // This sets up a 2x2 grid
    gap: 30px; // Space between images
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 700px) {
        max-width: 100%;
        display: flex;
        flex-direction: column;
    }

    .image-wrapper {
        position: relative;
        width: 100%;
        height: 500px; // This will ensure that the overlay also fits the grid cell
        margin: 1rem;
        border-radius: 10px;
        overflow: hidden; // Makes sure that everything inside, including the overlay, conforms to the rounded border
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 1700px) {
            margin-left: auto;
            margin-right: auto;
            height: 400px;
        }

        @media (max-width: 1400px) {
            margin-left: auto;
            margin-right: auto;
            height: 300px;
        }

        @media (max-width: 1100px) {
            margin-left: auto;
            margin-right: auto;
            height: 250px;
        }

        @media (max-width: 900px) {
            margin-left: auto;
            margin-right: auto;
            height: 200px;
        }

        @media (max-width: 700px) {
            margin-left: auto;
            margin-right: auto;
            height: 400px;
        }

        @media (max-width: 600px) {
            margin-left: auto;
            margin-right: auto;
            height: 350px;
        }

        @media (max-width: 480px) {
            margin-left: auto;
            margin-right: auto;
            height: 300px;
        }

        @media (max-width: 350px) {
            margin-left: auto;
            margin-right: auto;
            height: 250px;
        }

        @media (max-width: 300px) {
            margin-left: auto;
            margin-right: auto;
            height: 200px;
        }

        @media (max-width: 215px) {
            margin-left: auto;
            margin-right: auto;
            height: 175px;
        }

        @media (max-width: 175px) {
            margin-left: auto;
            margin-right: auto;
            height: 150px;
        }


        .cropped-image {
            max-width: 100%; // Ensures the image takes up the entire grid cell, adjust as needed
            // height: 500px; // Keeps the image's aspect ratio
            max-height: 100%;
            // margin: 1rem;
            width: auto;
            height: auto;
            object-fit: cover; // Ensures that the image covers the space without distortion
            padding: 0;
            border-radius: 15px;
            display: block; // Removes any alignment issues that could be caused by inline display
            // border: 1px solid #ccc; // Optional: a light border around images
        }

        .overlay {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transform: scale(0);
            transition: var(--transition-1);

            .download-container {
                text-decoration: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 1.5rem;
                color: var(--color-white);
                cursor: pointer;
                transition: var(--transition-1);

                h4 {
                    margin-bottom: 1rem;
                    font-size: 3rem;

                    @media (max-width: 1700px) {
                        font-size: 2rem;
                    }

                    @media (max-width: 1400px) {
                        font-size: 1.5rem;
                    }

                    @media (max-width: 1100px) {
                        margin-bottom: 0.5rem;
                        font-size: 1.5rem;
                    }

                    @media (max-width: 900px) {
                        margin-bottom: 0;
                        font-size: 1.0rem;
                    }

                    @media (max-width: 700px) {
                        margin-bottom: 0.5rem;
                        font-size: 1.2rem;
                    }

                    @media (max-width: 300px) {
                        margin-bottom: 0;
                        font-size: 1.0rem;
                    }

                    @media (max-width: 280px) {
                        margin-bottom: 0;
                        font-size: 0.75rem;
                    }

                    @media (max-width: 200px) {
                        margin-bottom: 0;
                        font-size: 0.6rem;
                    }
                }

                .download-svg {
                    width: 125px; // Adjust if necessary
                    height: 125px; // Adjust if necessary
                    margin-left: auto;
                    margin-right: auto;

                    path {
                        fill: var(--color-white);
                        transition: var(--transition-1);
                    }

                    @media (max-width: 1700px) {
                        width: 100px;
                        height: 100px;
                    }

                    @media (max-width: 1400px) {
                        width: 90px;
                        height: 90px;
                    }

                    @media (max-width: 300px) {
                        width: 75px;
                        height: 75px;
                    }

                    @media (max-width: 200px) {
                        width: 60px;
                        height: 60px;
                    }
                }

                &:hover {
                    color: var(--color-brand-icons-hover); // color changes on hover
                    opacity: 1;
                    transform: scale(1);

                    .download-svg path {
                        fill: var(--color-brand-icons-hover);
                    }
                }
            }

            .generate-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 1.5rem;
                color: var(--color-white);
                cursor: pointer;
                transition: var(--transition-1);

                @media (max-width: 700px) {
                    margin-top: 2rem;
                }

                @media (max-width: 350px) {
                    margin-top: 1rem;
                }

                @media (max-width: 200px) {
                    margin-top: 0;
                }

                button {
                    background: none;
                    border: none;
                    padding: 0;
                    margin: 0;
                    color: inherit; // Use the same font color as the container
                    font: inherit; // Use the same font settings as the container
                    cursor: pointer;
                    outline: inherit; // Use the same outline settings as the container

                    // Apply the same styles as h2
                    font-size: 1.0rem; // Example font-size, adjust as needed
                    font-weight: bold; // Example font-weight, adjust as needed

                    // Remove button specific styles
                    &:focus {
                        outline: none; // Removes the default focus outline
                    }

                    &:active {
                        outline: none; // Removes the default active outline
                    }

                    // Hover effect if needed
                    &:hover {
                        text-decoration: none; // Example hover effect, adjust as needed
                    }
                }

                h4 {
                    margin-bottom: 1rem;
                    font-size: 3rem;

                    @media (max-width: 1700px) {
                        font-size: 2rem;
                    }

                    @media (max-width: 1400px) {
                        font-size: 1.5rem;
                    }

                    @media (max-width: 1100px) {
                        margin-bottom: 0.5rem;
                        font-size: 1.5rem;
                    }


                    @media (max-width: 900px) {
                        margin-bottom: 0;
                        font-size: 1.0rem;
                    }

                    @media (max-width: 700px) {
                        margin-bottom: 0.5rem;
                        font-size: 1.2rem;
                    }

                    @media (max-width: 300px) {
                        margin-bottom: 0;
                        font-size: 1.0rem;
                    }

                    @media (max-width: 280px) {
                        margin-bottom: 0;
                        font-size: 0.75rem;
                    }

                    @media (max-width: 200px) {
                        margin-bottom: 0;
                        font-size: 0.6rem;
                    }
                }

                .generate-svg {
                    width: 125px; // Adjust if necessary
                    height: 125px; // Adjust if necessary
                    margin-left: auto;
                    margin-right: auto;

                    path {
                        fill: var(--color-white);
                        transition: var(--transition-1);
                    }

                    @media (max-width: 1700px) {
                        width: 100px;
                        height: 100px;
                    }

                    @media (max-width: 1400px) {
                        width: 90px;
                        height: 90px;
                    }

                    @media (max-width: 1100px) {
                        width: 75px;
                        height: 75px;
                    }

                    @media (max-width: 900px) {
                        width: 60px;
                        height: 60px;
                    }
                }

                &:hover {
                    color: var(--color-secondary); // color changes on hover
                    opacity: 1;
                    transform: scale(1);

                    .generate-svg path {
                        fill: var(--color-secondary);
                    }
                }
            }

        }

        &:hover .overlay {
            opacity: 0.94;
            transform: scale(1);
        }

    }
}