:root {
    /* Default size */
    --image-size: 500px;

    @media (max-width: 1800px) {
        --image-size: 400px;
    }

    @media (max-width: 1150px) {
        --image-size: 350px;
    }

    @media (max-width: 1000px) {
        --image-size: 400px;
    }

    @media (max-width: 650px) {
        --image-size: 350px;
    }

    @media (max-width: 500px) {
        --image-size: 300px;
    }

    @media (max-width: 500px) {
        --image-size: 250px;
    }

    @media (max-width: 350px) {
        --image-size: 225px;
    }

    @media (max-width: 290px) {
        --image-size: 200px;
    }

    @media (max-width: 250px) {
        --image-size: 175px;
    }

    @media (max-width: 220px) {
        --image-size: 145px;
    }

    @media (max-width: 190px) {
        --image-size: 120px;
    }
}

.image-slider {
    position: relative;
    min-width: var(--image-size);
    /* Set the width of the slider */
    min-height: var(--image-size);
    /* Set the height of the slider */
    overflow: hidden;
    border-radius: 1rem;
    margin-right: 1rem;

    @media (max-width: 1000px) {
        margin-right: 0;
    }
}

.image-slider img {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+ */
    user-select: none;
    /* Standard syntax */
    position: relative;
}

.slider-image {
    // width: 100%;
    height: var(--image-size);
    width: var(--image-size);
    display: block;
    /* Remove extra space below the image */
}

.generated-image {
    height: var(--image-size);
    width: var(--image-size);
}

.slider-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.slider-handle {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3px;
    /* Adjust the width of the slider handle */
    background-color: #fff;
    /* Choose a color for the handle */
    cursor: ew-resize;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}

.slider-arrow {
    background: url('../../assets/double-arrow.svg') no-repeat center center;
    /* Add your arrow icon */
    width: 50px;
    /* Size of the arrow icon */
    height: 50px;
    position: absolute;
    /* Position absolutely inside the slider handle */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    /* Ensure the arrow is on top of everything */
    // background: red;
    /* Temporary change for testing */
}

.image-label {
    position: absolute;
    bottom: 10px;
    /* Adjust as needed */
    color: white;
    /* Choose text color */
    font-size: 12px;
    /* Adjust font size */
    padding: 2px 5px;
    /* Adjust padding */
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background for readability */
    border-radius: 5px;
    /* Optional for rounded corners */

    @media (max-width: 250px) {
        font-size: 9px;
    }
}

.before-label {
    left: 10px;
    /* Adjust distance from left */
}

.after-label {
    right: 10px;
    /* Adjust distance from right */
}