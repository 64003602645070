.docs-main-container {
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1500px) {
        width: 80%;
    }

    @media (max-width: 1200px) {
        width: 90%;
    }

    @media (max-width: 1000px) {
        margin-top: 2rem;
        width: 95%;
    }

}

.docs-layout {
    display: flex;
    margin-top: 1rem;

    @media (max-width: 768px) {

        flex-direction: column;
    }
}

.sidebar {
    flex: 0 0 15%;
    padding: 0.2rem;
    overflow-y: auto;
    font-size: 0.8rem;
    // border: solid 1px var(--color-grey-1);
    // border-radius: 1rem;

    @media (max-width: 768px) {
        border: none;
        flex-basis: auto;
    }

    .docs-dropdown {
        display: none;

        @media (max-width: 768px) {
            display: block;
            margin: auto;
            margin-bottom: 1.5rem;
            font-size: 1rem;
        }
    }
}

.main-content--body {
    flex: 0 0 70%;
    padding: 1rem;
    border: solid 1px var(--color-grey-1);
    border-radius: 1rem;
    margin-left: 1rem;
    max-width: 90%;

    @media (max-width: 768px) {
        flex-basis: auto;
    }
}

.doc-link {
    display: block;
    color: var(--color-text);
    background-color: var(--color-brand-icons);
    border-radius: 0.3rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    text-decoration: none;
    cursor: pointer;
    color: var(--color-button-text);

    &:hover {
        background-color: var(--color-brand-icons-hover);
    }

    @media (max-width: 768px) {
        display: none;
    }
}


// @media (max-width: 768px) {


//     .sidebar {


//         .docs-dropdown {}

//         .docs-links {
//             display: none;
//         }
//     }

//     .main-content--body {
//         flex-basis: auto;
//     }
// }

.generic-docs-button {
    margin: 1rem;
    margin-left: 0rem;
    padding: 1rem 1rem;
    background-color: var(--color-secondary);
    font-size: 1rem;
    border: none;
    border-radius: 0.5rem;
    color: var(--color-text);
    cursor: pointer;
    align-self: flex-start;
    text-align: center;
    color: var(--color-button-text);

    &:hover {
        background-color: var(--color-brand-icons-hover);
    }

    @media (max-width: 400px) {
        padding: 1rem 1rem;
    }

    @media (max-width: 300px) {
        padding: 1rem 1rem;
        font-size: 0.75rem;
    }

}

.docs-image {
    max-width: 95%;
    display: block;
    margin: auto;
    border-radius: 2rem;
}

.docs-list {
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: 400;
    color: var(--color-text);

    .docs-list-item {
        list-style: disc;
        margin-left: 2rem;
    }
}

.docs-subheader {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: left;
}

.docs-subheader.table-of-contents {
    margin-bottom: 0.5rem;
}

.docs-read-time {
    color: var(--color-brand-icons);
    margin-bottom: 2rem;
    text-align: left;
}

.docs-title {
    margin: auto;
    margin-bottom: 1rem;
}

.docs-header {
    margin-bottom: 3rem;
    text-align: left;
}

.docs-button {
    margin: auto;
}

.horizontal-rule {

    @media (max-width: 768px) {
        margin-bottom: 1rem;
    }
}