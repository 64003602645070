.image-wrapper {
    position: relative;
    display: inline-flex; // changed to inline-flex for center alignment
    justify-content: center;
    align-items: center; // added for vertical centering
    align-self: center;

    .matted-image.text-center {
        display: block;
        max-height: 400px;
        max-width: 100%;
        height: auto;
        width: auto;
        border-radius: 15px;
        // border-radius: 15px; // added from reference style
        // border: 0.2px solid #191d2b; // added from reference style
        transition: var(--transition-1); // from your reference for smooth transitions
    }

    .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6); // changed to secondary color from your reference
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 15px; // added from reference style
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transform: scale(0);
        transition: var(--transition-1);

        .download-container {
            font-size: 3rem;
            color: var(--color-white); // changed to white for contrast
            cursor: pointer; // make it obvious it's clickable
            transition: var(--transition-1); // smooth transition on hover
            display: flex;
            flex-direction: column;
            align-items: center;

            h2 {
                margin-bottom: 1rem; // adds some spacing between the text and the SVG
            }

            &:hover {
                color: var(--color-brand-icons-hover); // color changes on hover

                .download-svg path {
                    fill: var(--color-brand-icons-hover); // black fill color on hover
                }
            }

            h4 {

                @media (max-width: 280px) {
                    font-size: 1.5rem;
                }

                @media (max-width: 200px) {
                    font-size: 1.0rem;
                }
            }

            .download-svg {
                padding: 0.5rem; // spacing around the SVG inside its box
                cursor: pointer; // make it obvious it's clickable
                transition: var(--transition-1); // smooth transition on hover
                width: 125px;
                height: 125px;
                // align-items: center;
                // justify-content: center;

                path {
                    fill: var(--color-white); // SVG color
                    transition: var(--transition-1); // smooth transition on hover
                }

                @media (max-width: 280px) {
                    width: 100px;
                    height: 100px;
                }

                @media (max-width: 200px) {
                    width: 75px;
                    height: 75px;
                }

            }

        }
    }

    &:hover .overlay {
        opacity: 0.94;
        transform: scale(1);
    }
}