// Mode Selector Styles

.mode-selector {
    width: 100%;
    margin-bottom: 3rem;
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.mode-selector label {
    font-size: calc(1rem + 0.5vw);
    min-width: 120px;
    background-color: var(--color-secondary);
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    color: var(--color-button-text);
    transition: background-color 0.3s ease;

    @media (max-width: 550px) {
        font-size: 1rem;
        padding: 5px 10px;
    }
}

.mode-selector input[type="radio"] {
    margin-right: 10px;
}

.mode-selector label:hover,
.mode-selector input[type="radio"]:checked+label {
    background-color: var(--color-brand-icons-hover);
}

.model-text {
    text-align: left;
    width: 100%;
    // margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;

    @media (max-width: 1100px) {
        font-size: 1.25rem;
    }

    @media (max-width: 768px) {
        text-align: center;
    }

    @media (max-width: 400px) {
        font-size: 1.0rem;
    }

}

.rasvec-label {
    @media (max-width: 768px) {
        text-align: center !important;
    }

    .hint {
        @media (max-width: 768px) {
            text-align: center;
        }

    }
}

// Slider Styles

.slider-container {
    margin-bottom: 15px;
    font-size: 1rem;
    width: 100%;
}

.slider-container label {
    display: block;
    margin-bottom: 2px;

    @media (max-width: 768px) {
        text-align: center;
    }
}

.slider-container input[type="range"] {
    display: block;
    width: calc(100% - 30px);
    // min-width: 100%;
    /* Center align if necessary */
    margin: 0 auto;
    cursor: pointer;
}

.custom-dropdown {
    width: calc(100% - 30px);
    text-align: center;
    font-size: 1.0rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
        width: calc(100% - 10px);
    }

    .custom-dropdown--user-input {
        width: 100%;
        margin-top: 0.5rem;
        padding: 0.5rem;
        text-align: center;
        box-sizing: border-box;
        background-color: var(--color-grey-3);
        color: var(--color-text);
        font-size: 1.0rem;
        font-weight: 500;

        @media (max-width: 768px) {
            font-size: 1.0rem;
            margin-right: 0;
            margin-left: 0;
        }

        @media (max-width: 400px) {
            font-size: 1.0rem;
        }
    }
}

.slider-value {
    display: inline-block;
    padding: 5px 10px;
    margin-left: 10px;
    background-color: var(--color-grey-3);
    color: var(--color-text);
    border-radius: 10px;
    font-weight: bold;
}

// Styling for boolean inputs

.custom-checkbox {
    margin-left: auto;
    margin-right: auto;
    font-size: 1rem;
    display: inline-block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;
    margin-top: 1rem;
    margin-bottom: 1.2rem;
    /* Prevent text selection */
}

/* Hide the browser's default checkbox */
.custom-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox (a square) */
.checkMark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 4px;
    transition: background-color 0.2s;
}

/* When the checkbox is checked, change the background and the content */
.custom-checkbox input:checked~.checkMark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkMark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked~.checkMark:after {
    display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkMark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}