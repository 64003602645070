.image-wrapper {
    position: relative;
    display: inline-flex; // changed to inline-flex for center alignment
    justify-content: center;
    align-items: center; // added for vertical centering
    align-self: center;
    width: auto;
    height: auto; // This will ensure that the overlay also fits the grid cell
    max-height: 500px;
    aspect-ratio: 1 / 1;
    margin: 1rem;
    border-radius: 10px;
    overflow: hidden; // Makes sure that everything inside, including the overlay, conforms to the rounded border
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1700px) {
        margin-left: auto;
        margin-right: auto;
        height: 400px;
    }

    @media (max-width: 1400px) {
        margin-left: auto;
        margin-right: auto;
        height: 300px;
    }

    @media (max-width: 1100px) {
        margin-left: auto;
        margin-right: auto;
        height: 250px;
    }

    @media (max-width: 900px) {
        margin-left: auto;
        margin-right: auto;
        height: 200px;
    }

    @media (max-width: 700px) {
        margin-left: auto;
        margin-right: auto;
        height: 400px;
    }

    @media (max-width: 600px) {
        margin-left: auto;
        margin-right: auto;
        height: 350px;
    }

    @media (max-width: 480px) {
        margin-left: auto;
        margin-right: auto;
        height: 300px;
    }

    @media (max-width: 350px) {
        margin-left: auto;
        margin-right: auto;
        height: 250px;
    }

    @media (max-width: 300px) {
        margin-left: auto;
        margin-right: auto;
        height: 200px;
    }

    @media (max-width: 215px) {
        margin-left: auto;
        margin-right: auto;
        height: 175px;
    }

    @media (max-width: 175px) {
        margin-left: auto;
        margin-right: auto;
        height: 150px;
    }

    .matted-image.text-center {
        max-height: 100%;
        max-width: 100%;
        height: auto;
        width: auto;
        object-fit: cover; 
        padding: 0;
        border-radius: 15px;
        transition: var(--transition-1); // from your reference for smooth transitions
        display: block;
    }

    .overlay {
        max-width: 100%;
        max-height: 100%;
        background-color: rgba(0, 0, 0, 0.6); // changed to secondary color from your reference
        position: absolute;
        left: 0;
        top: 0;
        // border-radius: 15px; // added from reference style
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transform: scale(0);
        transition: var(--transition-1);

        .download-container {
            text-decoration: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size:1.5rem;
            color: var(--color-white); // changed to white for contrast
            cursor: pointer; // make it obvious it's clickable
            transition: var(--transition-1); // smooth transition on hover

            h4 {
                margin-bottom: 1rem;
                font-size: 3rem;

                @media (max-width: 1700px) {
                    font-size: 2rem;
                }

                @media (max-width: 1400px) {
                    font-size: 1.5rem;
                }

                @media (max-width: 1100px) {
                    margin-bottom: 0.5rem;
                    font-size: 1.5rem;
                }

                @media (max-width: 900px) {
                    margin-bottom: 0;
                    font-size: 1.0rem;
                }

                @media (max-width: 700px) {
                    margin-bottom: 0.5rem;
                    font-size: 1.2rem;
                }

                @media (max-width: 300px) {
                    margin-bottom: 0;
                    font-size: 1.0rem;
                }

                @media (max-width: 280px) {
                    margin-bottom: 0;
                    font-size: 0.75rem;
                }

                @media (max-width: 200px) {
                    margin-bottom: 0;
                    font-size: 0.6rem;
                }
            }

            &:hover {
                color: var(--color-brand-icons-hover); // color changes on hover
                opacity: 1;
                transform: scale(1);

                .download-svg path {
                    fill: var(--color-brand-icons-hover); // black fill color on hover
                }
            }


            .download-svg {
                padding: 0.5rem; // spacing around the SVG inside its box
                cursor: pointer; // make it obvious it's clickable
                transition: var(--transition-1); // smooth transition on hover
                width: 125px;
                height: 125px;
                // align-items: center;
                // justify-content: center;

                path {
                    fill: var(--color-white); // SVG color
                    transition: var(--transition-1); // smooth transition on hover
                }

                @media (max-width: 1700px) {
                    width: 100px;
                    height: 100px;
                }

                @media (max-width: 1400px) {
                    width: 90px;
                    height: 90px;
                }

                @media (max-width: 300px) {
                    width: 75px;
                    height: 75px;
                }

                @media (max-width: 200px) {
                    width: 60px;
                    height: 60px;
                }

            }

        }
    }

    &:hover .overlay {
        opacity: 0.94;
        transform: scale(1);
    }
}

.image-wrapper--generated {
    position: relative;
    display: inline-flex; // changed to inline-flex for center alignment
    justify-content: center;
    align-items: center; // added for vertical centering
    align-self: center;
    width: auto;
    height: auto; // This will ensure that the overlay also fits the grid cell
    max-height: 500px;
    aspect-ratio: 1 / 1;
    margin: 1rem;
    border-radius: 10px;
    overflow: hidden; // Makes sure that everything inside, including the overlay, conforms to the rounded border
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1700px) {
        margin-left: auto;
        margin-right: auto;
        height: 400px;
    }

    @media (max-width: 1400px) {
        margin-left: auto;
        margin-right: auto;
        height: 300px;
    }

    @media (max-width: 1100px) {
        margin-left: auto;
        margin-right: auto;
        height: 250px;
    }

    @media (max-width: 900px) {
        margin-left: auto;
        margin-right: auto;
        height: 200px;
    }

    @media (max-width: 700px) {
        margin-left: auto;
        margin-right: auto;
        height: 400px;
    }

    @media (max-width: 600px) {
        margin-left: auto;
        margin-right: auto;
        height: 350px;
    }

    @media (max-width: 480px) {
        margin-left: auto;
        margin-right: auto;
        height: 300px;
    }

    @media (max-width: 350px) {
        margin-left: auto;
        margin-right: auto;
        height: 250px;
    }

    @media (max-width: 300px) {
        margin-left: auto;
        margin-right: auto;
        height: 200px;
    }

    @media (max-width: 215px) {
        margin-left: auto;
        margin-right: auto;
        height: 175px;
    }

    @media (max-width: 175px) {
        margin-left: auto;
        margin-right: auto;
        height: 150px;
    }

    .matted-image.text-center {
        height: 100%;
        width: 100%;
        margin-top: auto;
        margin-bottom: auto;
        object-fit: cover; 
        padding: 0;
        border-radius: 15px;
        transition: var(--transition-1); // from your reference for smooth transitions
        display: block;
    }

    .overlay {

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6); // changed to secondary color from your reference
        position: absolute;
        left: 0;
        top: 0;
        // border-radius: 15px; // added from reference style
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transform: scale(0);
        transition: var(--transition-1);

        .download-container {
            text-decoration: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size:1.5rem;
            color: var(--color-white); // changed to white for contrast
            cursor: pointer; // make it obvious it's clickable
            transition: var(--transition-1); // smooth transition on hover

            h4 {
                margin-bottom: 1rem;
                font-size: 3rem;

                @media (max-width: 1700px) {
                    font-size: 2rem;
                }

                @media (max-width: 1400px) {
                    font-size: 1.5rem;
                }

                @media (max-width: 1100px) {
                    margin-bottom: 0.5rem;
                    font-size: 1.5rem;
                }

                @media (max-width: 900px) {
                    margin-bottom: 0;
                    font-size: 1.0rem;
                }

                @media (max-width: 700px) {
                    margin-bottom: 0.5rem;
                    font-size: 1.2rem;
                }

                @media (max-width: 300px) {
                    margin-bottom: 0;
                    font-size: 1.0rem;
                }

                @media (max-width: 280px) {
                    margin-bottom: 0;
                    font-size: 0.75rem;
                }

                @media (max-width: 200px) {
                    margin-bottom: 0;
                    font-size: 0.6rem;
                }
            }

            &:hover {
                color: var(--color-brand-icons-hover); // color changes on hover
                opacity: 1;
                transform: scale(1);

                .download-svg path {
                    fill: var(--color-brand-icons-hover); // black fill color on hover
                }
            }


            .download-svg {
                padding: 0.5rem; // spacing around the SVG inside its box
                cursor: pointer; // make it obvious it's clickable
                transition: var(--transition-1); // smooth transition on hover
                width: 125px;
                height: 125px;
                // align-items: center;
                // justify-content: center;

                path {
                    fill: var(--color-white); // SVG color
                    transition: var(--transition-1); // smooth transition on hover
                }

                @media (max-width: 1700px) {
                    width: 100px;
                    height: 100px;
                }

                @media (max-width: 1400px) {
                    width: 90px;
                    height: 90px;
                }

                @media (max-width: 300px) {
                    width: 75px;
                    height: 75px;
                }

                @media (max-width: 200px) {
                    width: 60px;
                    height: 60px;
                }

            }

        }
    }

    &:hover .overlay {
        opacity: 0.94;
        transform: scale(1);
    }
}