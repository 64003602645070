// Changed git repo user email to track changes on commits

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
}

a {
    color: var(--color-links);

    &:focus {
        color: var(var(--color-links-highlight));
    }
}

// :root {
//     --color-primary: #FFF;
//     --color-secondary: #f1b23b;
//     --color-brand-icons: #f1b23b;
//     --color-brand-icons-hover: #ca8c19;
//     --color-links: #bd9a57;
//     --color-links: #196fe0;
//     --color-links: #6c7983;
//     --color-links-highlight: #ff7e1c;
//     --color-text: #000;
//     --color-white: #FFF;
//     --color-black: #000;
//     --color-grey0: #f8f8f8;
//     --color-grey-1: #6c7983;
//     --color-grey-2: #6c7983;
//     --color-grey-3: #e7e9eb;
//     --color-grey-4: #454e56;
//     --color-grey-5: #f8f8f8;
//     --color-grey-6: #12181b;
//     --color-grey-7: #f1b13b42;
//     --color-warn-1: #ff7e1c;
//     --color-label-1: #f8f8f8;
//     --color-price: #f1b23b;
//     --br-sm-2: 14px;
//     --br-md-2: 20px;
//     --box-shadow-1: 0 3px 15px rgba(0, 0, 0, 0.3);
//     --transition-1: all .4s ease-in-out;
//     --transition-2: all .2s ease-in-out;
//     --transition-3: all .65s ease-in-out;
//     --transition-4: background-color 0.3s ease;
// }

:root {
    --color-primary: #0088ff1e;
    --color-primary: #f8f8f8;
    --color-secondary: #5927E5;
    --color-brand-icons: #5927E5;
    --color-brand-icons-hover: #9B77FF;
    --color-links: #6c7983;
    --color-links-highlight: #5927E5;
    --color-call-to-action: #5927E5;
    --color-text: #454e56;
    --color-button-text: #fff;
    --color-pallete-matcher: #DCEEF5;
    --color-white: #FFF;
    --color-black: #000;
    --color-grey0: #f8f8f8;
    --color-grey-1: #6c7983;
    --color-grey-2: #6c7983;
    --color-grey-3: #d1d4d6;
    --color-grey-4: #454e56;
    --color-grey-5: #e4e2e2;
    --color-grey-6: #12181b;
    --color-grey-7: #f1b13b42;
    --color-grey-8: #6c7983;
    --color-warn-1: #ff7e1c;
    --color-label-1: #f8f8f8;
    --color-price: #e1d9f7;
    --color-header: #f8f8f8;
    --color-form: #cab9f6;
    --br-sm-2: 14px;
    --br-md-2: 20px;
    --box-shadow-1: 0 3px 15px rgba(0, 0, 0, 0.3);
    --transition-1: all .4s ease-in-out;
    --transition-2: all .2s ease-in-out;
    --transition-3: all .65s ease-in-out;
    --transition-4: background-color 0.3s ease;
}

// Old Dark Mode - 29.01.2024 

// .dark-mode {
//     --color-primary: #191d2b;
//     // --color-secondary: #27AE60;
//     --color-secondary: #0CC4C4;
//     --color-brand-icons: #0CC4C4;
//     --color-brand-icons-hover: #079393;
//     --color-links: #0CC4C4;
//     --color-links-highlight: #FFF;
//     --color-text: #FFF;
//     --color-white: #FFF;
//     --color-black: #000;
//     --color-grey0: #f8f8f8;
//     --color-grey-1: #dbe1e8;
//     --color-grey-2: #b2becd;
//     --color-grey-3: #6c7983;
//     --color-grey-4: #454e56;
//     --color-grey-5: #2a2e35;
//     --color-grey-6: #12181b;
//     --color-grey-7: #454e56;
//     --color-grey-8: #6c7983;
//     --color-warn-1: #fcf267b4;
//     --color-label-1: #fff;
//     --color-header: #426868;
//     // --color-price: #32ffff;
//     --color-price: #bcc1c1;
//     --color-form: #bcc1c1;
//     --color-form: #335959;
// }

.dark-mode {
    --color-primary: #191d2b;
    // --color-secondary: #27AE60;
    --color-secondary: #5927E5;
    --color-brand-icons: #5927E5;
    --color-brand-icons-hover: #9B77FF;
    --color-links: #FFF;
    --color-links-highlight: #ffff00;
    --color-call-to-action: #FFF;
    --color-text: #FFF;
    --color-white: #FFF;
    --color-black: #000;
    --color-grey0: #f8f8f8;
    --color-grey-1: #dbe1e8;
    --color-grey-2: #b2becd;
    --color-grey-3: #6c7983;
    --color-grey-4: #454e56;
    --color-grey-5: #2a2e35;
    --color-grey-6: #12181b;
    --color-grey-7: #454e56;
    --color-grey-8: #6c7983;
    --color-warn-1: #fcf267b4;
    --color-label-1: #fff;
    --color-header: #5927E5;
    // --color-price: #32ffff;
    --color-price: #e1d9f7;
    --color-form: #cab9f6;
}

// Global Slider settings

.custom-slider {
    -webkit-appearance: none;
    outline: none;
    box-sizing: border-box;
    background: none !important;
    padding: 0 !important;
}

.custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    outline: none;
    /* Required to customize WebKit's slider thumb */
    background-color: var(--color-grey-1);
    /* Color of the thumb */
    height: 16px;
    /* Height of the thumb */
    width: 16px;
    /* Width of the thumb */
    border: none;
    // border-radius: 75%;
    /* Makes the thumb circle-shaped */
    cursor: pointer;
    /* Makes a hand cursor appear on hover */
}

.custom-slider::-webkit-slider-runnable-track {
    outline: none;
    height: 16px;
    /* Color of the track */
    background-color: var(--color-secondary);
    border: none;
    cursor: pointer;
}

/* Global Slider settings for Mozilla */

.custom-slider {
    -moz-appearance: none;
    outline: none;
    box-sizing: border-box;
    background: none !important;
    padding: 0 !important;
}

.custom-slider::-moz-range-thumb {
    -moz-appearance: none;
    outline: none;
    background-color: var(--color-grey-1);
    /* Color of the thumb */
    height: 16px;
    /* Height of the thumb */
    width: 16px;
    /* Width of the thumb */
    border: none;
    /* Makes the thumb circle-shaped */
    // border-radius: 75%;
    cursor: pointer;
    /* Makes a hand cursor appear on hover */
}

.custom-slider::-moz-range-track {
    outline: none;
    height: 16px;
    background-color: var(--color-secondary);
    /* Color of the track */
    border: none;
    cursor: pointer;
}

/* This rule is for the progress part of the slider */
.custom-slider::-moz-range-progress {
    background-color: var(--your-progress-color-here);
    /* You can define your color for the progress */
}

.success {
    color: #0CC4C4;
}

.failure {
    color: var(--color-text);
    font-weight: 400;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    // font-weight: 700;
}

h1.site-title {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    text-align: center;
    font-size: 2.3rem;
    color: var(--color-text);
    font-weight: 500;

    @media (max-width: 1800px) {
        font-size: 2.0rem;
    }

    @media (max-width: 1600px) {
        font-size: 1.75rem;
    }

    @media (max-width: 1480px) {
        font-size: 1.5rem;
    }

    @media (max-width: 1300px) {
        font-size: 1.3rem;
    }

    @media (max-width: 1100px) {
        font-size: 1.1rem;
    }

    @media (max-width: 840px) {
        font-size: 1.2rem;
    }

    @media (max-width: 768px) {
        font-size: 1.6rem;
    }

    @media (max-width: 200px) {
        font-size: 0.8rem;
    }

}

h3.site-super-subtitle {
    color: var(--color-text);
    text-align: center;
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
    font-weight: 300;

    @media (max-width: 1800px) {
        font-size: 1.2rem;
    }

    @media (max-width: 1500px) {
        font-size: 1.0rem;
    }

    @media (max-width: 1300px) {
        font-size: 0.8rem;
    }

    @media (max-width: 840px) {
        font-size: 0.7rem;
    }

    @media (max-width: 768px) {
        font-size: 1rem;
    }

    @media (max-width: 200px) {
        font-size: 0.5rem;
    }
}

@keyframes flyRocket {
    0% {
        transform: translateY(0) rotate(-45deg);
        opacity: 1;
    }

    50% {
        transform: translateY(-20px) rotate(-45deg);
    }

    100% {
        transform: translateY(-40px) rotate(-45deg);
        opacity: 0;
    }
}

@keyframes zoomRocket {
    0% {
        transform: scale(1) translateY(0) rotate(-45deg);
        opacity: 1;
    }

    50% {
        transform: scale(1.2) translateY(-50px) rotate(-45deg);
        opacity: 0.8;
    }

    100% {
        transform: scale(2) translateY(-300px) rotate(-45deg);
        opacity: 0;
    }
}

@keyframes shakeRocket {

    0%,
    100% {
        transform: translateY(0) rotate(-45deg);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateY(-10px) rotate(-55deg);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateY(10px) rotate(-35deg);
    }
}

.rocket--small {
    display: inline-block;
    animation: flyRocket 2s ease-in-out infinite;
}

.rocket--big {
    margin-top: 2rem;
    font-size: 5rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    animation: flyRocket 2s ease-in-out infinite;
}

.rocket--shake {
    display: inline-block;
    animation: shakeRocket 2s ease-in-out infinite;
}

.hamburger-icon {
    display: none;
    cursor: pointer;
    margin-bottom: 5rem;
    margin-top: 3rem;
    transition: transform 0.3s ease;

    span {
        display: block;
        height: 5px;
        background: var(--color-text); // Adjust as per your theme
        margin: 5px;
        width: 30px;
        margin-left: auto;
        margin-right: auto;

        &.open {
            transform: translateY(220px);

            @media (max-width: 300px) {
                transform: translateY(140px);
            }

            &.authorized {
                transform: translateY(240px);

                @media (max-width: 300px) {
                    transform: translateY(190px);
                }

                @media (max-width: 250px) {
                    transform: translateY(240px);
                }
            }
        }
    }

    @media (max-width: 768px) {
        display: block;
    }
}

.header-bar {

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: none;
    /* or any color you prefer */
    padding: 0.3rem 1rem;
    z-index: 10;
    background-color: var(--color-header);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    /* ensure it's above other content */

    @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


}

.logo {
    font-size: 1.3rem;
    font-weight: 600;

    @media (max-width: 768px) {
        font-size: 1.2rem;
        font-weight: 500;
    }
}

.menu-button {
    text-align: center;
    margin: 0.5rem;
    margin-left: 0rem;
    padding: 0.2rem 0.6rem;
    background-color: var(--color-header);
    font-size: 1rem;
    font-weight: 400;
    border: 1px solid var(--color-grey-3);
    border-radius: 0.3rem;
    box-shadow: 0 0 0 1px var(--color-grey-3);
    color: var(--color-grey-2);
    cursor: pointer;
    align-self: center;
    text-align: center;
    transition: var(--transition-2);
    cursor: pointer;

    &:focus {
        outline-offset: 5px;
        background-color: var(--color-grey-3);
        border: 2px solid var(--color-grey-2);
        box-shadow: 0 0 0 2px var(--color-grey0) inset;
        overflow: hidden;
    }
}


.App-nav {
    width: 100%;
    display: grid;
    grid-template-columns: 15.0fr 0.1fr;
    text-align: left;
    // margin-top: 2rem;
    position: fixed;
    z-index: 5;
    background-color: var(--color-header);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    @media (max-width: 768px) {
        /* Reset columns */
        // grid-template-columns: none;
        /* Stack elements vertically */
        display: flex;
        flex-direction: column;
    }

    .App-navList {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        list-style: none;
        padding-top: 1rem;
        padding-bottom: 1rem;
        transition: transform 0.3s ease, opacity 0.3s ease;
        background-color: var(--color-header);

        // Hide on small screens
        @media (max-width: 768px) {
            margin: 0;
            display: none;
        }
    }

    .open {
        display: flex;
        opacity: 1;
        background-color: var(--color-header);

        // Hide on small screens
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            margin-top: 4rem;
            margin-bottom: 6rem;
            transform: translateY(2rem);
            // justify-content: center;
        }
    }

    li {

        @media (max-width: 768px) {
            margin-top: 3px;
            margin-bottom: 3px;
        }

        .App-link {
            color: var(--color-links);
            margin-left: 2rem;
            margin-right: 2rem;
            font-size: 1rem;
            text-decoration: none;

            // Reduce Font-size on medium screens
            @media (max-width: 1250px) {
                font-size: 1rem;
            }

            @media (max-width: 1100px) {
                font-size: 0.8rem;
                margin-left: 1rem;
                margin-right: 1rem;
            }

            // Increase Font-size  back on small screens
            @media (max-width: 768px) {
                font-size: 1.4rem;
            }

            @media (max-width: 300px) {
                font-size: 1.1rem;
            }

            &.active {
                color: var(--color-links-highlight);
                text-decoration: underline;
            }
        }

        .App-link.call-to-action {
            text-align: center;
            // margin: 1rem;
            margin-left: 0rem;
            padding: 0.5rem 0.5rem;
            font-size: 1rem;
            font-weight: 500;
            border: 2px solid var(--color-call-to-action);
            border-radius: 0.5rem;
            cursor: pointer;
            align-self: center;
            text-align: center;
            transition: var(--transition-2);
            background-color: var(--color-header);
            color: var(--color-call-to-action);

            &.active {
                color: var(--color-links-highlight);
                border: 2px solid var(--color-links-highlight);
                text-decoration: underline;
            }


            @media (max-width: 1200px) {
                padding: 0.4rem 0.4rem;
                font-size: 0.9rem;
            }


            @media (max-width: 1000px) {
                font-size: 0.8rem;
            }

            @media (max-width: 768px) {
                margin: 1rem;
                font-size: 1.4rem;
                font-weight: 400;
                margin-right: auto;
                margin-left: auto;

                &.active {
                    color: var(--color-links-highlight);
                    text-decoration: underline;
                }
            }

            @media (max-width: 300px) {
                font-size: 1.1rem;

                &.active {
                    color: var(--color-links-highlight);
                    text-decoration: underline;
                }
            }


            &:hover {
                background-color: var(--color-brand-icons-hover);
                color: var(--color-button-text);
            }

        }


    }
}

.App-nav--footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 0;
    margin-top: 8rem;
    padding-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1.5rem;
    background-color: var(--color-grey-3);
    color: var(--color-text);

    @media (max-width: 1300px) {
        flex-direction: column;
    }

    @media (max-width: 768px) {
        font-size: 0.8rem;
    }

    .App-navList--left {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        // width: 90%;
        list-style: none;
        transition: transform 0.3s ease;

        // Hide on small screens
        @media (max-width: 1300px) {
            padding: 0;
            margin: 0;
            flex-direction: column;
            align-self: center;
        }
    }

    .App-navList--right {
        width: 40%;
        text-align: right;
        justify-self: flex-end;
        align-self: center;

        @media (max-width: 1300px) {
            width: 100%;
            text-align: center;
            margin-top: 2rem;
        }
    }


    li {

        @media (max-width: 1300px) {
            margin-top: 1rem;
        }

        .App-link {
            color: var(--color-links);
            margin-left: 1rem;
            margin-right: 1rem;

            &.active {
                color: var(--color-links-highlight);
            }
        }

    }
}

body {
    transition: var(--transition-3);
    margin: 0;
    padding: 0;
    height: auto;
    overflow: auto;
}


.App {
    background-color: var(--color-primary);
    min-height: 100vh; // use min-height instead of height
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(8px + 2vmin);
    color: var(--color-text);
    transition: var(--transition-3);

    .app-loading-container {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-self: center;
        justify-self: center;

        .app-loading-spinner {
            margin-top: 2rem;
            stroke: var(--color-brand-icons);
            align-self: center;
            justify-self: center;
            margin-left: auto;
            margin-right: auto;
            display: block;

            animation: load-spin infinite 0.8s linear;

            @keyframes load-spin {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(360deg);
                }
            }
        }

    }

    .text-center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .small-text {
        font-size: 1.2rem;
    }

    .logged-in-component {
        margin-top: 1rem;
        font-size: 1.2rem;
    }

    .hidden {
        display: none;
    }

    .App-logo {
        height: 40vmin;
        pointer-events: none;

        .svg-logo {
            stroke: var(--color-brand-icons);
        }

        animation: App-logo-spin infinite 20s linear;

        @keyframes App-logo-spin {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }

    .main-content {
        width: 100%;
        margin-bottom: auto;
        margin-top: 10rem;
        color: var(--color-grey-2);
        height: 100%;
        transition: transform 0.3s ease;

        // &.nav-open {
        //     transform: translateY(-50px);
        // }

        @media (max-width: 1000px) {
            margin-top: 2rem;
        }

        @media (max-width: 768px) {
            margin-top: 5rem;
        }

        .App-home {

            margin-top: -10rem;
            margin-left: auto;
            margin-right: auto;

            @media(max-width: 1000px) {
                margin-top: 5rem;
            }

            .generic-button {
                text-align: center;
                margin: 1rem;
                margin-left: 0rem;
                padding: 1.0rem 1.0rem;
                background-color: var(--color-secondary);
                font-size: 1.2rem;
                font-weight: 500;
                border: 2px solid var(--color-secondary);
                border-radius: 0.5rem;
                color: var(--color-text);
                cursor: pointer;
                align-self: center;
                text-align: center;
                transition: var(--transition-2);
                color: var(--color-button-text);


                @media (max-width: 1420px) {
                    padding: 1.0rem 1.0rem;
                    font-size: 1.0rem;
                }

                @media (max-width: 1340px) {
                    padding: 0.8rem 0.8rem;
                    font-size: 0.8rem;
                }

                @media (max-width: 1220px) {
                    padding: 0.9rem 0.9rem;
                    font-size: 0.7rem;
                }

                @media (max-width: 1180px) {
                    padding: 0.6rem 0.6rem;
                    font-size: 0.7rem;
                }

                @media (max-width: 1070px) {
                    padding: 0.4rem 0.4rem;
                    font-size: 0.7rem;
                }


                @media (max-width: 768px) {
                    padding: 0.9rem 0.9rem;
                    font-size: 1.2rem;
                }

                @media (max-width: 720px) {
                    padding: 0.8rem 0.8rem;
                    font-size: 1.0rem;
                }

                @media (max-width: 650px) {
                    padding: 0.7rem 0.7rem;
                    font-size: 0.9rem;
                }

                @media (max-width: 390px) {
                    padding: 0.6rem 0.6rem;
                    font-size: 0.8rem;
                }

                @media (max-width: 350px) {
                    padding: 0.5rem 0.5rem;
                    font-size: 0.7rem;
                }

                @media (max-width: 310px) {
                    padding: 0.4rem 0.4rem;
                    font-size: 0.6rem;
                }

                @media (max-width: 230px) {
                    font-size: 0.6rem;
                    padding: 0.2rem 0.2rem;
                }

                &:hover {
                    background-color: var(--color-brand-icons-hover);
                }

            }

            .subtle-button {
                text-align: center;
                margin: 1rem;
                margin-left: 0rem;
                padding: 1.0rem 1.0rem;
                background-color: var(--color-primary);
                font-size: 1.2rem;
                font-weight: 500;
                border: 2px solid var(--color-secondary);
                border-radius: 0.5rem;
                color: var(--color-secondary);
                cursor: pointer;
                align-self: center;
                text-align: center;
                transition: var(--transition-2);


                @media (max-width: 1420px) {
                    padding: 1.0rem 1.0rem;
                    font-size: 1.0rem;
                }

                @media (max-width: 1340px) {
                    padding: 0.8rem 0.8rem;
                    font-size: 0.8rem;
                }

                @media (max-width: 1220px) {
                    padding: 0.9rem 0.9rem;
                    font-size: 0.7rem;
                }

                @media (max-width: 1180px) {
                    padding: 0.6rem 0.6rem;
                    font-size: 0.7rem;
                }

                @media (max-width: 1070px) {
                    padding: 0.4rem 0.4rem;
                    font-size: 0.7rem;
                }

                @media (max-width: 768px) {
                    padding: 0.9rem 0.9rem;
                    font-size: 1.2rem;
                }

                @media (max-width: 720px) {
                    padding: 0.8rem 0.8rem;
                    font-size: 1.0rem;
                }

                @media (max-width: 650px) {
                    padding: 0.7rem 0.7rem;
                    font-size: 0.9rem;
                }

                @media (max-width: 390px) {
                    padding: 0.6rem 0.6rem;
                    font-size: 0.8rem;
                }

                @media (max-width: 350px) {
                    padding: 0.5rem 0.5rem;
                    font-size: 0.7rem;
                }

                @media (max-width: 310px) {
                    padding: 0.4rem 0.4rem;
                    font-size: 0.6rem;
                }

                @media (max-width: 230px) {
                    font-size: 0.6rem;
                    padding: 0.2rem 0.2rem;
                }

                &:hover {
                    background-color: var(--color-brand-icons-hover);
                    color: var(--color-button-text);
                }

            }

            .App-landing--primary-block {
                width: 60%;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                margin-top: 17rem;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                @media (max-width: 1000px) {
                    margin-left: auto;
                    margin-right: auto;
                    flex-direction: column-reverse;
                    margin-top: 7rem;
                }

                @media (max-width: 768px) {
                    width: 80%;
                    margin-top: -4rem;
                }

                @media (max-width: 650px) {
                    width: 90%;
                    margin-top: -4rem;
                }

                .user-message {
                    text-align: center;
                }

                .App-landing-hero-right {
                    @media (max-width: 1000px) {
                        margin-bottom: 2rem;
                    }
                }

                .App-landing--gif {
                    height: 300px;
                    width: 300px;
                    border-radius: 5%;
                    margin-left: 5rem;
                    margin-right: 3rem;

                    @media (max-width: 1000px) {
                        margin-left: auto;
                        margin-right: auto;
                        height: 400px;
                        width: 400px;
                    }

                    @media (max-width: 768px) {
                        height: 350px;
                        width: 350px;
                    }

                    @media (max-width: 550px) {
                        height: 300px;
                        width: 300px;
                    }

                    @media (max-width: 450px) {
                        height: 250px;
                        width: 250px;
                    }

                    @media (max-width: 368px) {
                        height: 200px;
                        width: 200px;
                    }

                    @media (max-width: 300px) {
                        height: 150px;
                        width: 150px;
                    }

                    @media (max-width: 215px) {
                        height: 125px;
                        width: 125px;
                    }

                    @media (max-width: 175px) {
                        height: 100px;
                        width: 100px;
                    }
                }

                .App-landing--video {
                    height: 300px;
                    width: 300px;
                    border-radius: 5%;
                    margin-left: 5rem;
                    margin-right: 3rem;

                    @media (max-width: 1000px) {
                        margin-left: auto;
                        margin-right: auto;
                        height: 275px;
                        width: 275px;
                    }

                    @media (max-width: 768px) {
                        height: 250px;
                        width: 250px;
                    }

                    @media (max-width: 550px) {
                        height: 300px;
                        width: 300px;
                    }

                    @media (max-width: 450px) {
                        height: 200px;
                        width: 200px;
                    }

                    @media (max-width: 368px) {
                        height: 175px;
                        width: 175px;
                    }

                    @media (max-width: 300px) {
                        height: 150px;
                        width: 150px;
                    }

                    @media (max-width: 215px) {
                        height: 125px;
                        width: 125px;
                    }

                    @media (max-width: 175px) {
                        height: 100px;
                        width: 100px;
                    }
                }

                .App-landing--main-text-block {

                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    margin-left: 1rem;

                    @media (max-width: 1000px) {
                        justify-content: flex-start;
                        margin-left: 0;
                    }

                    .App-landing--title {
                        margin-top: 3rem;
                        margin-bottom: 0rem;
                        font-size: 2.0rem;
                        text-align: center;
                        line-height: 115%;
                        color: var(--color-text);
                        font-weight: 400;

                        @media (max-width: 1750px) {
                            font-size: 1.75rem;
                        }

                        @media (max-width: 1200px) {
                            font-size: 1.6rem;
                            margin-bottom: 0.5rem;
                        }

                        @media (max-width: 1000px) {
                            margin-top: 2rem;
                            font-size: 1.5rem;
                            margin-bottom: 1.5rem;
                        }

                        @media (max-width: 768px) {
                            font-size: 1.5rem;
                            margin-left: 0.25rem;
                            margin-right: 0.25rem;
                        }

                        @media (max-width: 550px) {
                            font-size: 1.25rem;
                        }

                        @media (max-width: 368px) {
                            font-size: 1.15rem;
                            margin-bottom: 1rem;
                        }

                        @media (max-width: 250px) {
                            font-size: 1.0rem;
                            margin-bottom: 0.75rem;
                        }

                        .App-landing--product {
                            color: var(--color-brand-icons);
                        }

                    }

                    .App-landing--subtitle {
                        margin-top: 1rem;
                        margin-bottom: 0.5rem;
                        font-size: 1.2rem;
                        text-align: center;
                        color: var(--color-text);

                        @media (max-width: 1800px) {
                            font-size: 1.25rem;
                            margin-top: 1rem;
                            margin-bottom: 0.5rem;
                        }

                        @media (max-width: 1200px) {
                            font-size: 1rem;
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                        }

                        @media (max-width: 1000px) {
                            margin-top: 0;
                            font-size: 1rem;
                            margin-bottom: 1rem;
                        }

                        @media (max-width: 768px) {
                            font-size: 1.15rem;
                        }

                        @media (max-width: 550px) {
                            font-size: 1rem;
                        }

                        @media (max-width: 368px) {
                            font-size: 1.25rem;
                            margin-top: 0.5rem;
                            margin-bottom: 0.5rem;
                        }

                        @media (max-width: 250px) {
                            font-size: 0.8rem;
                            margin-top: 0.25rem;
                            margin-bottom: 0.25rem;
                        }
                    }

                }
            }

            .App-landing--primary-block.secondary-copy {
                margin-bottom: 5rem;
            }

            .App-landing--primary-block.reversed {
                @media (max-width: 1000px) {
                    margin-top: 5rem;
                    flex-direction: column-reverse;
                }
            }

            .App-landing--secondary-block {
                margin-top: 10rem;
                margin-bottom: 10rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: auto;
                margin-left: auto;

                @media (max-width: 1000px) {
                    margin-top: 5rem;
                }

                @media (max-width: 768px) {
                    margin-bottom: 7rem;
                }

                @media (max-width: 350px) {
                    margin-top: 2rem;
                }

                @media (max-width: 250px) {
                    margin-top: 1rem;
                }

                .App-landing--secondary-title {
                    text-align: center;
                    font-size: 2.5rem;
                    color: var(--color-text);

                    @media (max-width: 1000px) {
                        margin-top: 2rem;
                    }

                    @media (max-width: 768px) {
                        font-size: 2rem;
                    }

                    @media (max-width: 550px) {
                        font-size: 1.5rem;
                    }

                    @media (max-width: 368px) {
                        font-size: 1.25rem;
                    }

                    @media (max-width: 250px) {
                        font-size: 1.0rem;
                    }
                }

                .App-landing--secondary-text-grid {
                    margin-top: 5rem;
                    display: grid;
                    /* Defines two columns with equal width */
                    grid-template-columns: 1fr 1fr;
                    /* Defines two rows with equal height */
                    // grid-template-rows: 1fr 1fr;
                    /* Optional: Defines the gap between the grid items */
                    // gap: 1rem;

                    .App-landing--secondary-text-grid-item {
                        margin: 0;
                        padding: 0;
                    }

                }

                .App-landing--secondary-copy {
                    font-size: 1.5rem;
                    font-weight: 700;
                    margin: 0.3rem;
                    // margin-left: 15rem;
                    // align-self: flex-start;

                    @media (max-width: 1000px) {
                        font-size: 1.25rem;
                    }

                    @media (max-width: 850px) {
                        font-size: 1.00rem;
                    }

                    @media (max-width: 450px) {
                        font-size: 0.75rem;
                    }

                    @media (max-width: 320px) {
                        font-size: 0.6rem;
                    }

                }


                .App-landing--secondary-grid {
                    display: grid;
                    /* Defines two columns with equal width */
                    grid-template-columns: 1fr 1fr;
                    /* Defines two rows with equal height */
                    grid-template-rows: 1fr 1fr;
                    /* Optional: Defines the gap between the grid items */
                    gap: 1rem;

                    .App-landing--secondary-grid-item {
                        margin: 0;
                        padding: 0;
                    }

                    .App-landing--grid-item-image {
                        height: 500px;
                        width: 500px;
                        margin: 1rem;
                        border-radius: 5%;
                        text-align: center;

                    }
                }

                .App-landing--secondary-image {
                    margin-top: -2rem;
                    margin-bottom: 8rem;
                    // margin-left: auto;
                    // margin-right: auto;
                    width: 800px;
                    height: auto;
                    border-radius: 5%;

                    @media (max-width: 1700px) {
                        width: 700px;
                    }

                    @media (max-width: 1200px) {
                        width: 600px;
                    }

                    @media (max-width: 850px) {
                        width: 500px;
                    }

                    @media (max-width: 768px) {
                        width: 400px;
                        margin-bottom: 5rem;
                    }

                    @media (max-width: 650px) {
                        width: 450px;
                    }

                    @media (max-width: 550px) {
                        width: 400px;
                        // margin-bottom: 3rem;
                    }

                    @media (max-width: 450px) {
                        width: 350px;
                    }

                    @media (max-width: 400px) {
                        width: 300px;
                    }

                    @media (max-width: 350px) {
                        width: 300px;
                    }

                    @media (max-width: 300px) {
                        width: 250px;
                    }

                    @media (max-width: 250px) {
                        width: 200px;
                    }

                    @media (max-width: 200px) {
                        width: 150px;
                    }

                    @media (max-width: 150px) {
                        width: 100px;
                    }

                }
            }
        }
    }
}

.App-landing--feature-block {
    margin-top: 5rem;
}

.generic-simple-button {
    text-align: center;
    margin: 1rem;
    margin-left: 0rem;
    padding: 1rem 1rem;
    background-color: var(--color-secondary);
    font-size: 1.15rem;
    font-weight: 700;
    border: none;
    border-radius: 1rem;
    color: var(--color-text);
    cursor: pointer;
    align-self: center;
    text-align: center;
    transition: var(--transition-2);
    color: var(--color-button-text);

    @media (max-width: 1200px) {
        padding: 0.9rem 0.9rem;
        font-size: 1.00rem;
    }

    @media (max-width: 368px) {
        font-size: 0.9rem;
    }

    @media (max-width: 250px) {
        font-size: 1.0rem;
        padding: 0.75rem 0.75rem;
    }

    &:hover {
        background-color: var(--color-brand-icons-hover);
    }

}

.faq--heading {
    font-size: 2.5rem;
    margin-bottom: 3rem;
    margin-top: 5rem;
    text-align: center;
    color: var(--color-text);

    @media (max-width: 768px) {
        font-size: 2rem
    }

    @media (max-width: 500px) {
        font-size: 1.75rem
    }

    @media (max-width: 260px) {
        font-size: 1.5rem
    }
}

@keyframes flipIn {
    0% {
        opacity: 0;
        transform: perspective(400px) rotateX(-90deg);
    }

    100% {
        opacity: 1;
        transform: perspective(400px) rotateX(0deg);
    }
}

@keyframes flipOut {
    0% {
        opacity: 1;
        transform: perspective(400px) rotateX(0deg);
    }

    100% {
        opacity: 0;
        transform: perspective(400px) rotateX(90deg);
    }
}

.animated-text {
    opacity: 1;
    // opacity: 1;
    // transform: perspective(400px) rotateX(90deg);
    // transition: width 2s;
    display: inline-block;
    /* or 'block' depending on your layout */
    width: 100%;
    // overflow: hidden;
    // white-space: nowrap;
    white-space: normal;
    // animation: flipOut 2.8s forwards;
    // font-size: 2.5rem;

    /* Keeps the text on one line */
}

.animated-text.visible {
    opacity: 1;
    // width: auto;
    // animation: flipIn 2.8s 0.2s forwards;
    /* Adjust as needed */
}

.h2-container {
    margin: 0;
    margin-top: 1rem;
    padding: 0;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 32%;
    padding: 0;
    color: var(--color-text);
}

.static-text {
    white-space: nowrap;
    margin-right: 0.5rem;
}

.generatebgDemo {
    width: 100%;
}

.generatebgDemo-rotated {
    // transform: rotateX(40deg);
    // transition: transform 2s ease-in-out;
}

.call-to-action-section {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1500px) {
        margin-top: 1rem;
    }

    @media (max-width: 1000px) {
        margin-top: 0.8rem;
    }

    @media (max-width: 768px) {
        margin-top: 0.8rem;
        margin-left: auto;
        margin-right: auto;
    }
}

@keyframes movingGradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.collage-super-wrapper {
    display: block;
    background: radial-gradient(circle at center, #5927E5, #7A4FF3, #9B77FF);
    background-size: 200% 200%;
    -webkit-animation: movingGradient 7s ease infinite;
    -moz-animation: movingGradient 7s ease infinite;
    animation: movingGradient 7s ease infinite;
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;

    @media (max-width: 1100px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .collage-copy-wrapper {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--color-primary);
        border-radius: 1rem;
        padding-top: 4rem;
        padding-bottom: 4rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        // box-shadow: 0 3px 15px rgba(255, 255, 255, 0.3);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        @media (max-width: 1700px) {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        @media (max-width: 1100px) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        @media (max-width: 768px) {
            width: 80%;
        }

        @media (max-width: 400px) {
            width: 90%;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        .collage-heading {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            margin-bottom: 2rem;
            font-size: 2rem;
            font-weight: 400;
            color: var(--color-text);

            @media (max-width: 1200px) {
                font-size: 1.5rem;
            }

            @media (max-width: 690px) {
                font-size: 1.3rem;
            }

            @media (max-width: 600px) {
                font-size: 1.2rem;
            }
        }

        .collage-text {
            width: 100%;
            font-size: 0.9rem;
            font-weight: 400;
            margin: 0;
            color: var(--color-text);
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            display: block;
            animation: blinker 2s ease infinite;

            @media (max-width: 768px) {
                font-size: 0.8rem;
            }

            @media (max-width: 400px) {
                font-size: 0.7rem;
            }
        }

        @keyframes blinker {
            50% {
                opacity: 0.3;
            }
        }
    }

}

.generic-center-button {
    display: block;
    text-align: center;
    margin: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1.0rem 1.0rem;
    background-color: var(--color-secondary);
    font-size: 1.2rem;
    font-weight: 500;
    border: 2px solid var(--color-secondary);
    border-radius: 0.5rem;
    color: var(--color-text);
    cursor: pointer;
    align-self: center;
    text-align: center;
    transition: var(--transition-2);
    color: var(--color-button-text);


    @media (max-width: 1420px) {
        padding: 1.0rem 1.0rem;
        font-size: 1.0rem;
    }

    @media (max-width: 1340px) {
        padding: 0.8rem 0.8rem;
        font-size: 0.8rem;
    }

    @media (max-width: 1220px) {
        padding: 0.9rem 0.9rem;
        font-size: 0.7rem;
    }

    @media (max-width: 1180px) {
        padding: 0.6rem 0.6rem;
        font-size: 0.7rem;
    }

    @media (max-width: 1070px) {
        padding: 0.4rem 0.4rem;
        font-size: 0.7rem;
    }


    @media (max-width: 768px) {
        padding: 0.9rem 0.9rem;
        font-size: 1.2rem;
    }

    @media (max-width: 720px) {
        padding: 0.8rem 0.8rem;
        font-size: 1.0rem;
    }

    @media (max-width: 650px) {
        padding: 0.7rem 0.7rem;
        font-size: 0.9rem;
    }

    @media (max-width: 390px) {
        padding: 0.6rem 0.6rem;
        font-size: 0.8rem;
    }

    @media (max-width: 350px) {
        padding: 0.5rem 0.5rem;
        font-size: 0.7rem;
    }

    @media (max-width: 310px) {
        padding: 0.4rem 0.4rem;
        font-size: 0.6rem;
    }

    @media (max-width: 230px) {
        font-size: 0.6rem;
        padding: 0.2rem 0.2rem;
    }

    &:hover {
        background-color: var(--color-brand-icons-hover);
    }

}

.laurel-container {
    position: relative;
    display: block;
    /* Change to inline-block for better control */
    width: auto;
    /* Let it grow as needed */
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.laurel {
    width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1350px) {
        width: 70%;
    }

    @media (max-width: 1250px) {
        width: 80%;
    }

    @media (max-width: 470px) {
        width: 70%;
    }

    @media (max-width: 400px) {
        width: 80%;
    }

    @media (max-width: 300px) {
        width: 90%;
    }

}

.site-subtitle,
.laurel-star-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    /* Center horizontally */
}

h2.site-subtitle {
    color: var(--color-text);
    text-align: center;
    font-size: 1.0rem;
    top: 0;
    // margin-top: 2.5rem;
    margin: 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0;
    font-weight: 400;

    @media (max-width: 1550px) {
        font-size: 0.9rem;
    }

    @media (max-width: 1383px) {
        top: -5%;
    }

    @media (max-width: 1050px) {
        font-size: 0.8rem;
    }

    @media (max-width: 1000px) {
        font-size: 1rem;
    }

    @media (max-width: 550px) {
        font-size: 0.9rem;
    }

    @media (max-width: 465px) {
        top: -10%;
    }

    @media (max-width: 300px) {
        font-size: 0.8rem;
    }

    @media (max-width: 270px) {
        font-size: 0.7rem;
    }

    @media (max-width: 235px) {
        top: 0%;
        font-size: 0.6rem;
    }

    @media (max-width: 200px) {
        line-height: 0.7rem;
        top: -5%;
    }
}

.laurel-star-container {
    top: 60%;
    /* Adjust based on where the stars fit best within the laurel */
    display: flex;
    gap: 5px;
    /* Manage space between stars */

    @media (max-width: 1383px) {
        top: 70%;
    }

    @media (max-width: 1000px) {
        top: 60%;
    }

    @media (max-width: 465px) {
        top: 65%;
    }

    @media (max-width: 300px) {
        top: 61%;
    }

    @media (max-width: 235px) {
        top: 62%;
    }

    @media (max-width: 200px) {
        top: 60%;
    }
}

.laurel-star {
    width: 20px;
    /* Adjust as necessary */
    height: auto;

    @media (max-width: 1383px) {
        width: 15px;
    }

    @media (max-width: 1050px) {
        width: 10px;
    }

    @media (max-width: 1000px) {
        width: 20px;
    }

    @media (max-width: 470px) {
        width: 15px;
    }

    @media (max-width: 300px) {
        width: 10px;
    }

    @media (max-width: 235px) {
        width: 8px;
    }

    g {
        fill: gold;
    }
}

.hero-footer-logos {
    margin-top: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3rem;

    @media (max-width: 470px) {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }

    span {
        font-size: 1.0rem;
        font-weight: 300;
        color: var(--color-text);
        transform: translateY(25%);

        // @media (max-width: 550px) {
        //     font-size: 0.9rem;
        // }
    }

    .hero-footer-logo {
        position: relative;
        height: 2rem;
        margin-left: 1rem;

        @media (max-width: 550px) {
            height: 1.5rem;
        }

        @media (max-width: 470px) {
            height: 2rem;
            margin-left: 0;
        }
    }

    .trustpilot {
        margin-top: 0.1rem;
        margin-left: 1rem;

        @media (max-width: 550px) {
            margin-top: 0.5rem;
        }

        @media (max-width: 470px) {
            margin-top: 2rem;
            margin-bottom: 1rem;
            margin-left: 0;
        }
    }

    .producthunt {
        margin-left: -2rem;
        margin-top: 0.2rem;

        @media (max-width: 550px) {
            margin-left: -3rem;
            margin-top: 0.6rem;
        }

        @media (max-width: 470px) {
            margin-left: 0;
            margin-bottom: 1rem;
        }
    }

    .hacker-news {
        height: 2.5rem;
    }
}