.themeButtonWrapper {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0.2rem;
    margin-right: 0.2rem;

    // @media (max-width: 1400px) {
    //     margin-right: 10rem;
    // }

    @media (max-width: 1100px) {
        margin-right: 0;
        margin-top: 0;
    }

    @media (max-width: 768px) {
        position: relative;
        display: block;
        /* Or 'relative' */
        margin-top: 0rem;
        /* Adjust margin as needed */
        width: 100%;
        /* Optional, for full width */
    }

    // @media (max-width: 500px) {
    //     margin-right: 1rem;
    // }

    // @media (max-width: 350px) {
    //     margin-right: 0.5rem;
    // }

    // @media (max-width: 275px) {
    //     margin-right: 0.25rem;
    // }

    // @media (max-width: 250px) {
    //     margin-right: 0;
    // }

    .themeButton {
        background: none;
        border: none;
        fill: var(--color-grey-2);
        // margin-top: 2rem;
        transition: var(--transition-2);

        &:hover {
            fill: var(--color-grey-3);
            cursor: pointer;
        }



        svg {
            width: 50px;
            height: 50px;

            @media (max-width: 1100px) {
                width: 40px;
                height: 40px;
            }

            @media (max-width: 1000px) {
                width: 35px;
                height: 35px;
            }

            @media (max-width: 850px) {
                width: 30px;
                height: 30px;
            }

            @media (max-width: 768px) {
                width: 50px;
                height: 50px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                // margin-right: auto;
            }
        }

    }
}