.Generate-Background-Title {
    font-size: 2.2rem;
    text-align: center;
    margin-bottom: 3rem;

    @media (max-width: 1000px) {
        margin-top: -2rem !important;
    }
}

.output--centered {

    margin-top: 5rem;

    .generated-images {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

.Generate-Background {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .Generate-Background--Left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 45%;

        @media (max-width: 1400px) {
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            width: 80%;
        }

        .matted-image {
            // margin-top: 2rem;
            // border: 1px solid var(--color-text);
            align-self: center;
            margin-bottom: 2rem;

        }

        &:hover .overlay {
            opacity: 0.94;
            transform: scale(1);
        }
    }

    .Generate-Background--Right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 45%;

        @media (max-width: 1400px) {
            margin-top: 1rem;
            margin-left: auto;
            margin-right: auto;
            align-items: center;
            width: 100%;
        }

        @media (max-width: 600px) {
            margin-top: 3rem;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            width: 80%;
        }

        .user-form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            @media (max-width: 1400px) {
                align-items: center;
            }

            .small-text {
                font-size: 1.0rem;
            }

            .large-text {
                font-size: 1.2rem;

                @media (max-width: 500px) {
                    font-size: 1rem;
                }
            }

            .crop-container {
                align-self: center;
                text-align: center;

                // .crop-img {
                //     max-width: 100%;
                //     max-height: 100%;
                // }

            }

            .user-label {
                display: flex;
                flex-direction: column;
                text-align: left;
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 1rem;
                font-size: 1.2rem;

                @media (max-width: 400px) {
                    font-size: 1rem;
                }

            }

            .user-input {
                width: 100%;
                margin-top: 0.5rem;
                padding: 0.5rem;
                box-sizing: border-box;
                background-color: var(--color-grey-3);
                color: var(--color-text);
                font-size: 1.2rem;

                @media (max-width: 400px) {
                    font-weight: 1rem;
                }
            }

            .slider {}

            .user-button {
                text-align: center;
                margin: 1rem;
                margin-left: 0rem;
                padding: 1.0rem 1.0rem;
                background-color: var(--color-secondary);
                font-size: 1.3rem;
                font-weight: 500;
                border: 2px solid var(--color-secondary);
                border-radius: 0.5rem;
                color: var(--color-text);
                cursor: pointer;
                align-self: center;
                text-align: center;
                transition: var(--transition-2);
                color: var(--color-button-text);

                &:hover {
                    background-color: var(--color-brand-icons-hover);
                }

                @media (max-width: 768px) {
                    padding: 1.0rem 1.0rem;
                    font-size: 1.2rem;
                }

                @media (max-width: 400px) {
                    padding: 0.8rem 0.8rem;
                    font-size: 1rem;
                }


            }

        }

        .user-message {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            text-align: center;
            align-self: center;
            transition: var(--transition-1);
            overflow-wrap: break-word;
        }

        .gen-loader {

            display: flex;
            flex-direction: column;

            .gen-loading {
                margin-top: 2rem;
                stroke: var(--color-brand-icons);
                text-align: center;
                align-self: center;
                animation: load-spin infinite 0.8s linear;

                @keyframes load-spin {
                    from {
                        transform: rotate(0deg);
                    }

                    to {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }

    .hint {
        font-size: 1rem;
        margin: 0;


        @media (max-width: 400px) {
            font-size: 0.8rem;
        }

    }

    .gen-image {
        // margin-top: 2rem;
        // border: 1px solid var(--color-text);
        align-self: center;
    }
}

.image-count-status {
    font-size: 0.9rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: var(--color-price);
}