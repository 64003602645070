.popup-overlay {
    position: fixed;
    top: 0rem;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: var(--transition-2);
}

.popup-content {
    background: var(--color-primary);
    padding: 20px;
    // border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    text-align: left;
    position: relative;
    /* Limit the height to the viewport height */
    max-height: 80vh;
    /* Limit the width to the viewport width */
    max-width: 90vw;
    /* Allow vertical scrolling */
    overflow-y: auto;


    @media(max-width: 520px) {
        /* 80% of the viewport height */
        max-height: 80vh;
        /* 90% of the viewport width */
        max-width: 90vw;
        /* Allows vertical scrolling if the content overflows */
        overflow-y: auto;
        border-radius: 10px;
    }

    p {
        font-size: 1.2rem;

        @media (max-width: 520px) {
            font-size: 1rem;
        }
    }

    li {
        font-size: 1rem;
        list-style: disc;
        margin-left: 2rem;

        @media (max-width: 520px) {
            font-size: 0.8rem;
        }
    }

    .guide-image {
        display: block;
        width: 400px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;

        @media (max-width: 520px) {
            width: 350px;
        }

        @media (max-width: 370px) {
            width: 300px;
        }

        @media (max-width: 300px) {
            width: 250px;
        }

        @media (max-width: 265px) {
            width: 200px;
        }

        @media (max-width: 200px) {
            width: 150px;
        }
    }
}

.info-button {
    margin-left: 10px;
    cursor: pointer;
    transition: var(--transition-2);
    text-decoration: underline;
    font-weight: 700;
    color: var(--color-links);

    &:hover {
        color: var(--color-links-highlight);
    }

}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 2rem;
    cursor: pointer;
    color: var(--color-text);
    /* Style the button as needed, e.g., font size, color */
}

.generic-button--center {
    display: block;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1.3rem 1.3rem;
    background-color: var(--color-secondary);
    font-size: 1rem;
    border: none;
    border-radius: 3rem;
    color: var(--color-text);
    cursor: pointer;
    align-self: center;
    text-align: center;
    color: var(--color-button-text);

    &:hover {
        background-color: var(--color-brand-icons-hover);
    }

    @media (max-width: 400px) {
        padding: 1rem 1rem;
    }

    @media (max-width: 300px) {
        padding: 1rem 1rem;
        font-size: 0.75rem;
    }

}