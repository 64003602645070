.pricing-main-container {

    margin-top: -5rem;
    padding: 2rem;

    @media (max-width: 1000px) {
        margin-top: 4rem;
    }

    @media (max-width: 768px) {
        margin-top: 0rem;
        padding: 1rem;
    }

    .pricing-title {
        margin-top: 2rem;
        margin-bottom: 3.0rem;
        text-align: center;
        color: var(--color-text);
        font-weight: 500;
        font-size: 2.5rem;

        @media (max-width: 768px) {
            font-size: 2rem;
        }

        @media (max-width: 400px) {
            font-size: 1.5rem;
        }
    }

    .pricing-text {
        margin: 1rem;
        font-size: 0.8rem;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 490px) {
            margin-bottom: 2rem;
        }

        @media (max-width: 300px) {
            margin-bottom: 3rem;
            font-size: 0.7rem;
        }
    }

    .pricing-secondary-container {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-around;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 2rem;
        margin-bottom: 5rem;
        flex-grow: 1;
        min-width: 1200px; // Control the maximum width of the container here
        gap: 4rem; // Spacing between package cards
        box-sizing: border-box;

        @media (max-width: 1260px) {
            margin: 0 auto;
            margin-top: 2rem;
            margin-bottom: 3rem;
            min-width: fit-content;
            flex-direction: column;
            justify-content: flex-start;
            gap: 6rem;
        }

        @media (max-width: 490px) {
            gap: 0rem;
        }

    }

    .pricing-tertiary-container {

        @media (max-width: 490px) {
            // Translates y in sync with package cards
            transform: translateY(-120px);
            margin-top: -1rem;
        }

    }

    .package-card {
        flex: 1 1 calc(53.333% - 4rem); // Roughly 1/3 width minus gap, this makes 3 cards side by side
        box-shadow: 0px 0px 10px var(--color-grey-1); // Slight shadow for elevation
        padding: 15px;
        border-radius: 10px;
        max-width: 100%;
        transition: transform 0.3s ease;
        max-width: 325px;
        box-sizing: border-box;


        @media (max-width: 490px) {
            // Slightly scales down non-highlighted cards
            transform: scale(0.8) translateY(-120px);
        }

        @media (max-width: 360px) {
            // Slightly scales down non-highlighted cards
            transform: scale(0.6) translateY(-210px);
            margin-bottom: -5rem;
        }

        @media (max-width: 330px) {
            // Slightly scales down non-highlighted cards
            transform: scale(0.5) translateY(-275px);
            margin-bottom: -8rem;
        }

        @media (max-width: 300px) {
            // Slightly scales down non-highlighted cards
            transform: scale(0.4) translateY(-490px);
            margin-bottom: -12.9rem;
        }

        &.highlighted {
            flex: 1 1 calc(50% - 4rem); // Makes the highlighted card a bit wider
            transform: scale(1.2); // Slightly scales up the highlighted card
            background-color: var(--color-price);
            color: var(--color-grey-8);

            @media (max-width: 490px) {
                // Slightly scales down highlighted card
                transform: scale(1.0) translateY(-100px);
            }

            @media (max-width: 360px) {
                // Slightly scales down highlighted card
                transform: scale(0.8) translateY(-150px);
            }

            @media (max-width: 330px) {
                // Slightly scales down highlighted card
                transform: scale(0.7) translateY(-200px);
            }

            @media (max-width: 300px) {
                // Slightly scales down highlighted card
                transform: scale(0.6) translateY(-325px);
            }

        }

        .package-card-title {
            margin-top: 0; // Remove default margin
            font-size: 2rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            text-align: center;

            @media (max-width: 490px) {
                height: 20px;
            }

            @media (max-width: 250px) {
                // Slightly scales down highlighted card
                font-size: 1.5rem;
            }
        }

        .package-card-price {
            display: grid;
            grid-template-rows: repeat(2, 1fr); // 2 rows
            grid-template-columns: repeat(2, 1fr, 0.5fr); // 2 columns
            align-items: center;
            margin-bottom: 1rem;

            .package-card-price-number {
                grid-row: 1 / 3; // Span from row 1 to row 3 (2 rows in total)
                grid-column: 1 / 2; // Span the first column
                font-size: 3rem; // Adjust as needed
                margin-right: 0.5rem;
                justify-self: end;
                // color: var(--color-price);
            }

            .package-card-price-text-1 {
                font-size: 1.15rem;
                // grid-row: 1 / 2; // Occupy the top row
                grid-column: 2 / 3; // Occupy the second column
                margin-bottom: -0.6rem;
                // color: var(--color-price);
            }

            .package-card-price-text-2 {
                font-size: 1.15rem;
                grid-row: 2 / 3; // Occupy the bottom row
                grid-column: 2 / 3; // Occupy the second column
                margin-top: -0.6rem;
                // color: var(--color-price);
            }
        }

        .package-card-button {
            font-size: 2rem;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 1rem;
            cursor: pointer;
            padding: 10px 20px;
            border: none;
            border-radius: 10px;
            background-color: var(--color-secondary); // Choose your desired button color
            color: #FFF;
            transition: var(--transition-4);

            &:hover {
                background-color: var(--color-brand-icons-hover); // Darker shade on hover
            }
        }

        // .package-card-option input[type="radio"]:checked+label {
        //     color: var(--color-secondary); // You can choose any color you like for the selected package
        // }

        .package-card-option-free {
            font-size: 1.1rem;
            display: flex;
            flex-direction: column;

            .open-lock {
                fill: var(--color-text);
                width: 60px;
                height: 60px;
                align-self: center;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
            }

            .package-card-list {
                font-size: 1.1rem;

                .package-card-list-item {
                    margin: 0.8rem;
                    display: flex;
                    align-items: flex-start;

                    .package-card-colored-tick {
                        flex-shrink: 0; // This prevents the tick from shrinking when the text content is too long
                        width: 2rem; // Adjust this width to space out the tick from the text
                        text-align: center;
                        color: var(--color-secondary);
                    }

                    .package-card-list-text {
                        flex-grow: 1; // This allows the text to take up the rest of the space in the list item
                    }
                }
            }

        }


        .package-card-option {
            font-size: 0.9rem;
            display: grid;
            grid-template-columns: 0.1fr 3fr 1fr 2fr;
            align-items: center;
            /* adjust this value to control the spacing between grid items */
            gap: 0.3rem;

            input {
                width: 0.9rem;
                height: 0.9rem;
                margin: 0.5rem;
            }

            label {
                /* this will make label not to create a new box, letting the grid structure flow */
                display: contents;

                .price,
                .third-moment {
                    font-size: 0.7rem;
                }

                .moment-combo {

                    .first-moment {
                        font-size: 0.8rem;
                    }

                    .second-moment {
                        font-size: 0.7rem;
                    }
                }
            }
        }

        .package-card-includes {
            font-size: 1.2rem;
        }
    }

    .generic-error {
        margin: 0;
        color: var(--color-warn-1);
        width: 100%;
        padding: 0;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: block;

        p {
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
    }
}