.tools-container {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1000px) {
        margin-top: 10rem;
    }

    @media (max-width: 768px) {
        margin-top: 3rem;
        width: 95%;
    }
}

.tool-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    background-color: var(--color-form);
    color: var(--color-text);

    &:hover {
        transform: scale(1.05);
    }
}

.tool-icon {
    font-size: 48px;
    margin-bottom: 20px;
}

.tool-info h3 {
    margin: 0;
    font-size: 24px;
    text-align: center;

    @media (max-width: 350px) {
        font-size: 18px;
    }
}

.tool-info p {
    text-align: center;

    @media (max-width: 350px) {
        font-size: 10px;
    }
}