h2.testimonial-header {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2rem;
    font-weight: 400;
    color: var(--color-text);

    @media (max-width: 1200px) {
        font-size: 1.5rem;
    }

    @media (max-width: 690px) {
        font-size: 1.3rem;
    }

    @media (max-width: 600px) {
        font-size: 1.2rem;
    }
}

span.heart {
    color: var(--color-secondary);
}

div.testimonial {
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12rem;
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    justify-content: space-around;
    grid-gap: 2rem;
    width: 60%;
    max-width: 1200px;

    @media (max-width:1200px) {
        grid-template-columns: repeat(2, 1fr);
        /* Adjust for smaller screens */
        width: 60%;
        /* You might want to increase the width percentage on smaller screens */
    }

    @media (max-width:800px) {
        grid-template-columns: repeat(1, 1fr);
        /* Adjust for smaller screens */
        width: 80%;
        /* You might want to increase the width percentage on smaller screens */
    }

    @media (max-width: 350px) {
        width: 90%;
    }
}

div.testimonial:hover {
    cursor: default;
}

// div.testimonial>div.card:nth-child(1) {
//     grid-area: large;
// }

// div.testimonial>div.card:nth-child(2) {
//     grid-area: medium;
// }

// div.testimonial>div.card:nth-child(3) {
//     grid-area: small;
// }

// div.testimonial>div.card:nth-child(4) {
//     grid-area: medium;
// }

// div.testimonial>div.card:nth-child(5) {
//     grid-area: large;
// }

// div.testimonial>div.card:nth-child(6) {
//     grid-area: small;
// }

div.testimonial>div.card {
    width: 350px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    // background-color: #333;
    background-color: var(--color-grey-3);
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    border-radius: 2rem;

    @media (max-width: 400px) {
        width: 300px;
        padding: 10px;
        border-radius: 1rem;
    }

    @media (max-width: 350px) {
        width: 250px;
        padding: 10px;
    }

    @media (max-width: 290px) {
        width: 200px;
        padding: 5px;
    }

    @media (max-width: 240px) {
        width: 175px;
    }

    @media (max-width: 210px) {
        width: 150px;
    }
}

div.testimonial>div.card>div.layer {
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #5927E5, #7A4FF3, #9B77FF);
    z-index: 1;
    transition: 0.5s;
}

div.testimonial>div.card:hover>div.layer {
    top: 0;
}

div.testimonial>div.card>div.content {
    position: relative;
    z-index: 2;
}

div.testimonial>div.card>div.content>p {
    font-size: 0.8rem;
    line-height: 24px;
    color: var(--color-text);
    transition: 0.4s;

    @media (max-width: 400px) {
        font-size: 0.7rem;
    }
}

div.testimonial>div.card:hover>div.content>p {
    color: var(--color-primary);
}

div.testimonial>div.card>div.content>div.image {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid var(--color-primary);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

div.testimonial>div.card>div.content>div.details>h2 {
    font-size: 18px;
    color: var(--color-text);
    transition: 0.4s;

    @media (max-width: 350px) {
        font-size: 14px;
    }

}

div.testimonial>div.card>div.content>div.details>h2>span {
    font-size: 14px;
    color: var(--color-secondary);
    transition: 0.4s;

    @media (max-width: 350px) {
        font-size: 12px;
    }
}

div.testimonial>div.card:hover>div.content>div.details>h2>span {
    color: var(--color-primary);
}

div.testimonial>div.card:hover>div.content>div.details>h2 {
    color: var(--color-primary);
}

div.testimonial>div.card>div.content>h3.card-title {
    margin-top: 1rem;
    font-size: 1.2rem;
    transition: 0.4s;

    @media (max-width: 400px) {
        font-size: 1rem;
    }
}

div.testimonial>div.card:hover>div.content>h3.card-title {
    color: var(--color-primary);
}

.star {
    // color: gold;
    margin-top: 0.8rem;
    // color: yellow;
    font-size: 1.2rem;
    /* Adjust size as needed */
}

.stars {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    /* Space below the stars */
}