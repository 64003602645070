.faq-section {
    width: 50%;

    @media (max-width: 1000px) {
        width: 70%;
    }

    @media (max-width: 768px) {
        width: 80%;
    }

    @media (max-width: 250px) {
        padding: 0.1rem;
    }
}

.faq-item {
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: var(--color-text);
    font-size: 1.2rem;
    font-weight: 500;

    @media (max-width: 1000px) {
        font-size: 1rem
    }

    @media (max-width: 300px) {
        font-size: 0.8rem
    }

    @media (max-width: 200px) {
        font-size: 0.6rem
    }
}

.faq-icon {
    margin-left: 2rem;
    font-weight: bold;

    @media (max-width: 250px) {
        margin-left: 0;
    }
}

.faq-answer {
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: 400;

    @media (max-width: 1000px) {
        font-size: 0.9rem
    }

    @media (max-width: 300px) {
        font-size: 0.7rem
    }

    @media (max-width: 200px) {
        font-size: 0.5rem
    }

    /* Add any additional styles for the answer text */
}