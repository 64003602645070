.main-content {

    .common-styles {


        @media (max-width: 1000px) {
            margin-top: 6rem;
        }

        @media (max-width: 600px) {
            margin-left: auto;
            margin-right: auto;
            width: 80%;
        }

        @media (max-width: 500px) {
            width: 90%;
        }

        h1 {
            font-size: 2.5rem;
            display: block;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2rem;
            color: var(--color-text);
            font-weight: 500;

            @media (max-width: 1100px) {
                font-size: 1.5rem;
            }

            @media (max-width: 850px) {
                font-size: 1.25rem;
            }

            @media (max-width: 400px) {
                font-size: 1.0rem;
                margin-bottom: 2rem;
            }
        }

        h2 {
            font-size: 2rem;
            color: var(--color-grey-2);

            @media (max-width: 1100px) {
                font-size: 1.5rem;
            }

            @media (max-width: 850px) {
                font-size: 1.25rem;
            }

            @media (max-width: 400px) {
                font-size: 1.0rem;
            }
        }

        h3 {
            color: var(--color-grey-2);
        }

        .user-form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            .crop-container {
                margin-top: 1.2rem;
                align-self: center;
                text-align: center;
                transform-origin: top left;
                transition: transform 0.2s ease-in-out;
                width: 60%;

                @media (max-width: 1500px) {
                    width: 70%;
                }

                @media (max-width: 1300px) {
                    width: 80%;
                }

                @media (max-width: 1100px) {
                    width: 90%;
                }

                @media (max-width: 950px) {
                    width: 100%;
                }

                // .crop-img {
                //     max-height: 60%;
                // }

            }

            .original-image-container-outer {
                align-self: center;
                text-align: center;

                .original-img-container-inner {
                    align-self: center;
                    text-align: center;

                    .original-img {
                        max-width: 100%;
                        max-height: 400px;
                        height: auto;
                        border-radius: 15px;
                        margin-top: 2rem;
                        margin-bottom: 2rem;

                        @media (max-width: 400px) {
                            margin-top: 2rem;
                            max-width: 80%;
                        }
                    }
                }
            }



            .forgot-link {
                margin-top: 1rem;
                align-self: center;
                font-size: 1.5rem;
                color: var(--color-text);
                font-weight: 300;

                &:hover {
                    color: var(--color-grey-2);
                }

                @media (max-width: 400px) {
                    font-size: 1rem;
                }
            }
        }

        .user-label {
            display: flex;
            text-align: left;
            flex-direction: column;
            width: 100%;
            margin-bottom: 1rem;
            font-size: 1.5rem;

            @media (max-width: 1100px) {
                font-size: 1.25rem;
            }

            @media (max-width: 400px) {
                font-size: 1.0rem;
            }

            .small-text {

                font-size: 1rem;
                display: none;

                @media (max-width: 950px) {
                    font-size: 1.0rem;
                }

                @media (max-width: 768px) {
                    font-size: 0.75rem;
                }

                @media (max-width: 400px) {
                    font-size: 0.6rem;
                }

            }
        }

        .user-input {
            width: 100%;
            margin-top: 0.5rem;
            padding: 0.5rem;
            box-sizing: border-box;
            background-color: var(--color-grey-3);
            color: var(--color-text);
            font-size: 1.5rem;

            @media (max-width: 768px) {
                font-size: 1.2rem;
            }

            @media (max-width: 400px) {
                font-size: 1.0rem;
            }
        }

        .checkbox-label {
            display: block;
            /* Aligns checkbox and text vertically */
            align-items: center;
            text-align: left;
            font-size: 1.0rem;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            color: var(--color-text);

            @media (max-width: 1100px) {
                font-size: 0.8rem;
            }
        }

        .checkbox-label input[type="checkbox"] {
            /* Adds some space between the checkbox and the text */
            margin-right: 0.5rem;
            // margin-bottom: 1rem;
            /* Width of the checkbox */
            width: 12px;
            /* Height of the checkbox */
            height: 12px;
            /* Changes cursor to pointer to indicate it's clickable */
            cursor: pointer;
            /* Removes default styling on WebKit browsers */
            -webkit-appearance: none;
            /* Removes default styling on Mozilla browsers */
            -moz-appearance: none;
            /* Removes default styling */
            appearance: none;
            /* Background color of checkbox */
            background-color: #f0f0f0;
            /* Border color of checkbox */
            border: 1px solid #dcdcdc;
            /* Rounded corners for checkbox */
            border-radius: 3px;
        }

        /* Style for when checkbox is checked */
        .checkbox-label input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
            /* Background color when checked */
            /* You can add more styles for the checked state */
        }

        .file-loader {

            font-size: 1.2rem;

            @media (max-width: 400px) {
                font-size: 1rem;
            }

            @media (max-width: 250px) {
                font-size: 0.75rem;
            }

            @media (max-width: 200px) {
                font-size: 0.6rem;
            }

            @media (max-width: 165px) {
                font-size: 0.5rem;
            }

        }

        .file-loader-text {
            margin: 0;
            font-size: 1rem;

            @media (max-width: 400px) {
                font-size: 0.8rem;
            }

            @media (max-width: 250px) {
                font-size: 0.75rem;
            }
        }

        .user-button {
            text-align: center;
            margin: 1rem;
            margin-left: 0rem;
            padding: 1.0rem 1.0rem;
            background-color: var(--color-secondary);
            font-size: 1.2rem;
            font-weight: 500;
            border: 2px solid var(--color-secondary);
            border-radius: 0.5rem;
            color: var(--color-text);
            cursor: pointer;
            align-self: center;
            text-align: center;
            transition: var(--transition-2);
            color: var(--color-button-text);

            &:hover {
                background-color: var(--color-brand-icons-hover);
            }

            @media (max-width: 1420px) {
                padding: 1.0rem 1.0rem;
                font-size: 1.0rem;
            }

            @media (max-width: 1340px) {
                padding: 0.8rem 0.8rem;
                font-size: 0.8rem;
            }

            @media (max-width: 1220px) {
                padding: 0.9rem 0.9rem;
                font-size: 0.7rem;
            }

            @media (max-width: 1180px) {
                padding: 0.6rem 0.6rem;
                font-size: 0.7rem;
            }

            @media (max-width: 1070px) {
                padding: 0.4rem 0.4rem;
                font-size: 0.7rem;
            }


            @media (max-width: 768px) {
                padding: 0.9rem 0.9rem;
                font-size: 1.2rem;
            }

            @media (max-width: 720px) {
                padding: 0.8rem 0.8rem;
                font-size: 1.0rem;
            }

            @media (max-width: 650px) {
                padding: 0.7rem 0.7rem;
                font-size: 0.9rem;
            }

            @media (max-width: 390px) {
                padding: 0.6rem 0.6rem;
                font-size: 0.8rem;
            }

            @media (max-width: 350px) {
                padding: 0.5rem 0.5rem;
                font-size: 0.7rem;
            }

            @media (max-width: 310px) {
                padding: 0.4rem 0.4rem;
                font-size: 0.6rem;
            }

            @media (max-width: 230px) {
                font-size: 0.6rem;
                padding: 0.2rem 0.2rem;
            }

        }

        .unsubscribe-button {
            margin: 1rem;
            padding: 1.0rem 1.0rem;
            background-color: var(--color-primary);
            border: 1px solid var(--color-grey-2);
            font-size: 0.7rem;
            border-radius: 0.5rem;
            cursor: pointer;
            align-self: center;
            text-align: center;
            opacity: 0.5;
            color: var(--color-text);
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-top: 2rem;

            &:hover {
                background-color: var(--color-grey-2);
            }

            @media (max-width: 400px) {
                padding: 1rem 1rem;
            }

            @media (max-width: 300px) {
                padding: 1rem 1rem;
                font-size: 0.75rem;
            }

        }

        .cancel-unsubscribe-button {
            margin: 1rem;
            margin-left: 0rem;
            padding: 1.0rem 1.0rem;
            background-color: var(--color-secondary);
            font-size: 1.5rem;
            border: none;
            border-radius: 0.5rem;
            cursor: pointer;
            align-self: center;
            text-align: center;
            color: var(--color-text);
            margin-left: auto;
            margin-right: auto;
            display: block;

            &:hover {
                background-color: var(--color-brand-icons-hover);
            }

            @media (max-width: 400px) {
                padding: 1rem 1rem;
            }

            @media (max-width: 300px) {
                padding: 1rem 1rem;
                font-size: 0.75rem;
            }

        }

        .generic-account-button {
            text-align: center;
            margin: 1rem;
            margin-left: 0rem;
            padding: 1.0rem 1.0rem;
            background-color: var(--color-secondary);
            font-size: 1.2rem;
            font-weight: 500;
            border: 2px solid var(--color-secondary);
            border-radius: 0.5rem;
            color: var(--color-text);
            cursor: pointer;
            align-self: center;
            text-align: center;
            transition: var(--transition-2);
            color: var(--color-button-text);


            @media (max-width: 1420px) {
                padding: 1.0rem 1.0rem;
                font-size: 1.0rem;
            }

            @media (max-width: 1340px) {
                padding: 0.8rem 0.8rem;
                font-size: 0.8rem;
            }

            @media (max-width: 1220px) {
                padding: 0.9rem 0.9rem;
                font-size: 0.7rem;
            }

            @media (max-width: 1180px) {
                padding: 0.6rem 0.6rem;
                font-size: 0.7rem;
            }

            @media (max-width: 1070px) {
                padding: 0.4rem 0.4rem;
                font-size: 0.7rem;
            }


            @media (max-width: 768px) {
                padding: 0.9rem 0.9rem;
                font-size: 1.2rem;
            }

            @media (max-width: 720px) {
                padding: 0.8rem 0.8rem;
                font-size: 1.0rem;
            }

            @media (max-width: 650px) {
                padding: 0.7rem 0.7rem;
                font-size: 0.9rem;
            }

            @media (max-width: 390px) {
                padding: 0.6rem 0.6rem;
                font-size: 0.8rem;
            }

            @media (max-width: 350px) {
                padding: 0.5rem 0.5rem;
                font-size: 0.7rem;
            }

            @media (max-width: 310px) {
                padding: 0.4rem 0.4rem;
                font-size: 0.6rem;
            }

            @media (max-width: 230px) {
                font-size: 0.6rem;
                padding: 0.2rem 0.2rem;
            }

            &:hover {
                background-color: var(--color-brand-icons-hover);
            }

        }

        .generic-account-button--center {
            display: block;
            margin-top: 2rem;
            margin-left: auto;
            margin-right: auto;
            padding: 1.0rem 1.0rem;
            background-color: var(--color-secondary);
            font-size: 1rem;
            border: none;
            border-radius: 0.5rem;
            color: var(--color-text);
            cursor: pointer;
            align-self: center;
            text-align: center;
            color: var(--color-button-text);

            &:hover {
                background-color: var(--color-brand-icons-hover);
            }

            @media (max-width: 400px) {
                padding: 1rem 1rem;
            }

            @media (max-width: 300px) {
                padding: 1rem 1rem;
                font-size: 0.75rem;
            }

        }

        .account-info {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem; // padding: 1.3rem 0;
            font-size: 1.5rem;

            @media (max-width: 500px) {
                font-size: 1.25rem;
            }

            @media (max-width: 300px) {
                font-size: 1rem;
            }
        }

        .unordered-list {
            font-size: 1.5rem;
            margin-top: 1.0rem;

            .list-item {
                margin-top: 0.5rem;

                a {
                    color: var(--color-secondary);
                }
            }

            @media (max-width: 950px) {
                font-size: 1.25rem;
            }

            @media (max-width: 780px) {
                font-size: 1.0rem;
            }

            @media (max-width: 450px) {
                font-size: 0.8rem;
            }

            @media (max-width: 330px) {
                font-size: 0.6rem;
            }

            @media (max-width: 250px) {
                font-size: 0.5rem;
            }
        }

        .user-button-disabled {
            background-color: var(--color-grey-3);
            cursor: not-allowed;
            color: var(--color-grey-2);

            &:hover {
                background-color: var(--color-grey-3);
                cursor: not-allowed;
            }
        }


        .user-message {
            margin-top: 1rem;
            margin-bottom: 0.2rem;
            text-align: center;
            align-self: center;
            transition: var(--transition-1);
            overflow-wrap: break-word;
            color: var(--color-secondary);
            display: block;
            margin-left: auto;
            margin-right: auto;
            font-size: 1rem;

            @media (max-width: 768px) {
                font-size: 0.8rem;
            }

            @media (max-width: 250px) {
                font-size: 0.8rem;
            }
        }

        .matt-loading {
            margin-top: 0.5rem;
            stroke: var(--color-brand-icons);
            text-align: center;
            align-self: center;
            animation: load-spin infinite 0.8s linear;


            @keyframes load-spin {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(360deg);
                }
            }
        }

        .matted-image {
            // margin-top: 2rem;
            // border: 1px solid var(--color-text);
            margin-top: 2rem;
            margin-bottom: 2rem;
            height: 400px;
            border-radius: 15px;
            align-self: center;
        }

        .Generate-Background--Left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 45%;

            .matted-image {
                // margin-top: 2rem;
                // border: 1px solid var(--color-text);
                align-self: center;
            }
        }

        .Generate-Background--Right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 45%;
        }

        .image-count-status {
            font-size: 0.9rem;
            font-weight: 700;
            margin-top: 1rem;
            margin-bottom: 3rem;
            color: var(--color-price);
        }

        .payment-title {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            width: 80%
        }

        .payment-message {
            margin-top: 2rem;
            margin-left: auto;
            margin-right: auto;
            text-align: center;

            width: 80%;
        }

        .payment-link {
            width: 80%;
            display: block;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            color: var(--color-secondary);
        }
    }

}

.form-card {
    background-color: var(--color-form);
    color: var(--color-text);
    font-weight: 500;
    /* Card background color */
    padding: 2rem;
    /* Card padding */
    border-radius: 15px;
    /* Rounded corners for the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    max-width: 400px;
    /* Maximum width of the card */
    margin: 2rem auto;
    /* Center the card with automatic horizontal margins */
    text-align: center;
    /* Center the text inside the card */

    @media (max-width: 768px) {
        padding: 1.5rem;
        max-width: 400px;
        /* Make the card take more width on smaller screens */
    }

    @media (max-width: 530px) {
        max-width: 90%;
        /* Make the card take more width on smaller screens */
    }

    @media (max-width: 400px) {
        padding-bottom: 2rem;
        padding-top: 2rem;
    }
}

.processing-card {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    background-color: var(--color-primary);
    color: var(--color-text);
    font-weight: 500;
    /* Card background color */
    padding: 2rem;
    /* Card padding */
    border-radius: 15px;
    /* Rounded corners for the card */
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 8px var(--color-grey-1);
    /* Subtle shadow for depth */
    max-width: 800px;
    /* Maximum width of the card */
    margin: 2rem auto;
    /* Center the card with automatic horizontal margins */
    text-align: center;
    /* Center the text inside the card */

    @media (max-width: 768px) {
        padding: 1.5rem;
        max-width: 400px;
        /* Make the card take more width on smaller screens */
    }

    @media (max-width: 530px) {
        max-width: 100%;
        /* Make the card take more width on smaller screens */
    }

    @media (max-width: 400px) {
        padding-bottom: 2rem;
        padding-top: 2rem;
    }
}

.file-loader-label {
    font-size: 1.2rem;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
}

.image-request-wrapper {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.manage-subscription {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
}