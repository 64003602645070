// .collage {
//     display: grid;
//     grid-template-columns: repeat(4, 1fr);
//     /* Adjust the number of columns */
//     grid-template-rows: auto;
//     /* Adjust row sizes */
//     gap: 10px;
//     /* Space between images */
//     padding: 10px;
//     margin-bottom: 3rem;
// }

// .collage-item {
//     width: 100%;
//     object-fit: cover;
//     border-radius: 0.5rem;
//     /* Adjust how images fit into the grid items */
// }

// /* Individual item styling (optional) */
// .collage-item-1 {
//     grid-area: 1 / 1 / 3 / 3;
// }

// .collage-item-2 {
//     grid-area: 1 / 3 / 2 / 4;
// }

// .collage-item-3 {
//     grid-area: 2 / 3 / 3 / 4;
// }

/* ...and so on for each image */

/* New Styles */

@keyframes movingGradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.collage {
    display: grid;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(3, 1fr);
    /* Adjust for 3 columns */
    grid-template-rows: repeat(3, 1fr);
    /* Adjust for 3 rows */
    gap: 10px;
    padding: 10px;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.collage-item {
    width: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
}

.collage-button {
    grid-column: 2;
    /* Place the button in the second column */
    grid-row: 2;
    /* Place the button in the second row */
    z-index: 2;
    /* Make sure the button is above the images */
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    font-weight: 500;
    border: 2px solid var(--color-secondary);
    border-radius: 0.5rem;
    color: var(--color-text);
    cursor: pointer;
    align-self: center;
    text-align: center;
    transition: var(--transition-2);
    color: var(--color-button-text);
    /* Gradient Options */
    // background: radial-gradient(circle, #5927E5, #7A4FF3, #9B77FF);
    // background: linear-gradient(45deg, #9B77FF, #5927E5, #7A4FF3);
    // background: linear-gradient(to bottom right, #9B77FF 25%, #5927E5 25%, #5927E5 50%, #7A4FF3 50%, #7A4FF3 75%, #9B77FF 75%);
    // background: linear-gradient(to right, #9B77FF, #5927E5, #7A4FF3);
    background: conic-gradient(#9B77FF,
            #5927E5,
            #7A4FF3,
            #9B77FF);
    background-size: 200% 200%;
    -webkit-animation: movingGradient 7s ease-in-out infinite;
    -moz-animation: movingGradient 7s ease-in-out infinite;
    animation: movingGradient 7s ease-in-out infinite;



    @media (max-width: 1500px) {
        font-size: 1.2rem;
    }

    @media (max-width: 1340px) {
        font-size: 1rem;
    }


    // @media (max-width: 1070px) {
    //     font-size: 0.8rem;
    // }

    // @media (max-width: 920px) {
    //     font-size: 0.7rem;
    // }

    // @media (max-width: 875px) {
    //     font-size: 0.6rem;
    // }


    @media (max-width: 768px) {
        font-size: 1.2rem;
    }

    @media (max-width: 720px) {
        font-size: 1.0rem;
    }

    @media (max-width: 650px) {
        font-size: 0.9rem;
    }

    @media (max-width: 475px) {
        font-size: 0.8rem;
    }

    &:hover {
        background-color: var(--color-brand-icons-hover);
    }

}