@keyframes movingGradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.demo-subtitle {
    font-size: 1.25rem;
    color: var(--color-primary);

    @media (max-width: 350px) {
        font-size: 1rem;
    }

    @media (max-width: 250px) {
        font-size: 0.75rem;
    }
}

.demo-wrapper {
    // background-color: var(--color-grey-5);
    // background: linear-gradient(270deg, var(--color-secondary), var(--color-grey0), blue);
    background: radial-gradient(circle at center, #5927E5, #7A4FF3, #9B77FF);
    background-size: 200% 200%;
    -webkit-animation: movingGradient 7s ease infinite;
    -moz-animation: movingGradient 7s ease infinite;
    animation: movingGradient 7s ease infinite;
    color: white;
    width: 100%;
    padding: 2rem;
}

.demo-products {
    margin-top: 0rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-image {
    height: 350px;
    width: 350px;
    border-radius: 1rem;
    margin-bottom: 0;
    margin: 2rem;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.3);

    @media(max-width: 1450px) {
        height: 300px;
        width: 300px;
    }

    @media(max-width: 1250px) {
        height: 275px;
        width: 275px;
    }

    @media(max-width: 1150px) {
        height: 250px;
        width: 250px;
    }

    @media(max-width: 1000px) {
        height: 225px;
        width: 225px;
    }

    @media(max-width: 900px) {
        height: 200px;
        width: 200px;
    }

    @media(max-width: 800px) {
        height: 175px;
        width: 175px;
        margin: 1.5rem
    }

    @media(max-width: 700px) {
        height: 150px;
        width: 150px;
        margin: 1.5rem
    }

    @media(max-width: 670px) {
        height: 125px;
        width: 125px;
        margin: 1.5rem
    }

    @media(max-width: 600px) {
        height: 200px;
        width: 200px;
        margin: 1.25rem
    }

    @media(max-width: 530px) {
        height: 175px;
        width: 175px;
        margin: 1.25rem
    }

    @media(max-width: 475px) {
        height: 150px;
        width: 150px;
        margin: 1.00rem
    }

    @media(max-width: 400px) {
        height: 125px;
        width: 125px;
        margin: 0.75rem
    }

    @media(max-width: 330px) {
        height: 100px;
        width: 100px;
        margin: 0.50rem
    }

    @media(max-width: 280px) {
        height: 75px;
        width: 75px;
        margin: 0.25rem
    }

    @media(max-width: 210px) {
        height: 50px;
        width: 50px;
        margin: 0.15rem
    }

    &:hover {
        cursor: pointer;
    }
}

.highlighted-product {
    height: 400px;
    width: 400px;
    // border: 2px solid var(--color-secondary);
    transition: var(--transition-3);

    @media(max-width: 800px) {
        height: 350px;
        width: 350px;
    }

    @media(max-width: 750px) {
        height: 300px;
        width: 300px;
    }

    @media(max-width: 640px) {
        height: 275px;
        width: 275px;
    }

    @media(max-width: 600px) {
        height: 400px;
        width: 400px;
    }

    @media(max-width: 550px) {
        height: 350px;
        width: 350px;
    }

    @media(max-width: 500px) {
        height: 300px;
        width: 300px;
    }

    @media(max-width: 430px) {
        height: 275px;
        width: 275px;
    }

    @media(max-width: 390px) {
        height: 250px;
        width: 250px;
    }

    @media(max-width: 350px) {
        height: 225px;
        width: 225px;
    }

    @media(max-width: 315px) {
        height: 200px;
        width: 200px;
    }

    @media(max-width: 280px) {
        height: 175px;
        width: 175px;
    }

    @media(max-width: 240px) {
        height: 150px;
        width: 150px;
    }

    @media(max-width: 210px) {
        height: 125px;
        width: 125px;
    }

    @media(max-width: 190px) {
        height: 100px;
        width: 100px;
    }
}

.hidden-product {
    display: none;
    transition: var(--transition-3);
}

.demo-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 1.3rem 1.3rem;
    // background-color: #F9F871;
    font-size: 1rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    align-self: center;
    text-align: center;
    color: #333333;
    font-weight: 500;
    /* Test Styles */
    background: radial-gradient(circle at center, #9B77FF, #5927E5, #7A4FF3);
    background-size: 200% 200%;
    -webkit-animation: movingGradient 5s ease-in-out infinite;
    -moz-animation: movingGradient 5s ease-in-out infinite;
    animation: movingGradient 5s ease-in-out infinite;
    color: white;
    border: 2px solid #fff;

    &:hover {
        background: radial-gradient(circle at center, #5927E5, #9B77FF, #7A4FF3);
        -webkit-animation: movingGradient 3s ease infinite;
        -moz-animation: movingGradient 3s ease infinite;
        animation: movingGradient 3s ease infinite;
    }

    @media (max-width: 400px) {
        padding: 1rem 1rem;
    }

    @media (max-width: 300px) {
        padding: 1rem 1rem;
        font-size: 0.75rem;
    }

    @media (max-width: 250px) {
        padding: 0.75rem 0.75rem;
        font-size: 0.6rem;
    }

    @media (max-width: 200px) {
        padding: 0.25rem 0.25rem;
        font-size: 0.5rem;
    }

    @media (max-width: 160px) {
        padding: 0.15rem 0.15rem;
        font-size: 0.4rem;
    }

}

.demo-generated-images {
    display: flex;
    margin-bottom: 0rem;
    justify-content: center;
    align-items: center;

    @media(max-width: 1250px) {
        flex-direction: column;
    }
}

.demo-generated-image {
    height: 400px;
    width: 400px;
    border-radius: 1rem;
    margin: 2rem;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.3);

    @media(max-width: 1550px) {
        height: 300px;
        width: 300px;
    }

    @media(max-width: 1250px) {
        height: 400px;
        width: 400px;
    }

    @media(max-width: 800px) {
        height: 350px;
        width: 350px;
        margin: 1.5rem;
    }

    @media(max-width: 750px) {
        height: 300px;
        width: 300px;
        margin: 1.25rem;
    }

    @media(max-width: 640px) {
        height: 275px;
        width: 275px;
    }

    @media(max-width: 600px) {
        height: 400px;
        width: 400px;
    }

    @media(max-width: 550px) {
        height: 350px;
        width: 350px;
    }

    @media(max-width: 500px) {
        height: 300px;
        width: 300px;
    }

    @media(max-width: 430px) {
        height: 275px;
        width: 275px;
    }

    @media(max-width: 390px) {
        height: 250px;
        width: 250px;
    }

    @media(max-width: 350px) {
        height: 225px;
        width: 225px;
        margin: 1rem;
    }

    @media(max-width: 315px) {
        height: 200px;
        width: 200px;
    }

    @media(max-width: 280px) {
        height: 175px;
        width: 175px;
        margin: 0.75rem;
    }

    @media(max-width: 240px) {
        height: 150px;
        width: 150px;
    }

    @media(max-width: 210px) {
        height: 125px;
        width: 125px;
    }

    @media(max-width: 190px) {
        height: 100px;
        width: 100px;
        margin: 0.5rem;
    }

}

.svg-wrapper {
    position: relative;
    margin-left: 35%;
    width: 120px;
    height: 120px;

    @media(max-width: 768px) {
        margin-left: 25%;
    }

    @media(max-width: 670px) {
        width: 100px;
        height: 100px;
    }

    @media(max-width: 550px) {
        margin-left: 15%;
    }

    @media(max-width: 480px) {
        margin-left: 7%;
    }

    @media(max-width: 300px) {
        width: 80px;
        height: 80px;
    }
}

.svg-wrapper-hidden {
    display: none;
    width: 0;
    height: 0;
}

@keyframes smoothBounce {

    0%,
    100% {
        transform: translateY(-20px);
        animation-timing-function: ease-in;
    }

    50% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
}


.try-it-out-icon {
    position: relative;
    top: 2rem;
    // left: 40rem;


    g {
        path {
            // fill: var(--color-secondary);
            stroke: var(--color-primary);
            stroke-width: 4;
        }
    }

    // z-index: -1;
    animation: smoothBounce 1s infinite;

    @media (max-width: 800px) {
        top: 1.6rem;
    }

    @media (max-width: 600px) {
        top: 1.4rem;
    }

    @media (max-width: 475px) {
        top: 1.1rem;
    }

    @media (max-width: 475px) {
        top: 1.0rem;
    }

    @media (max-width: 330px) {
        top: 0.7rem;
    }

    @media (max-width: 280px) {
        top: 0.5rem;
    }

}

.App-landing--steps {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    font-size: 2rem;
    // max-width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border: solid 1px var(--color-grey-1);
    background-color: #fff;
    border-radius: 1rem;
    padding: 0.5rem 0rem;
    color: var(--color-text);

    @media (max-width: 1000px) {
        width: 80%;
        font-size: 1rem;
        padding: 0.1rem 0.2rem;
    }

    @media (max-width: 768px) {
        padding: 0.5rem 0.5rem;
        font-size: 0.8rem;
        width: 100%;
    }

    @media (max-width: 530px) {
        padding: 0.2rem 0.2rem;
        font-size: 0.7rem;
    }

    @media (max-width: 335px) {
        padding: 0.1rem 0.1rem;
        font-size: 0.6rem;
    }

    @media (max-width: 270px) {
        padding: 1rem 1rem;
        font-size: 1rem;
    }


    .steps-text {
        width: 25%;
        font-size: 1rem;

        @media (max-width: 1200px) {
            font-size: 0.8rem;

            @media (max-width: 850px) {
                font-size: 0.7rem;
            }

            @media (max-width: 768px) {
                font-size: 0.8rem;
                width: 100%;
            }

            @media (max-width: 650px) {
                font-size: 0.7rem;
                width: 100%;
            }

            @media (max-width: 575px) {
                font-size: 0.6rem;
            }

            @media (max-width: 335px) {
                font-size: 0.5rem;
            }

            @media (max-width: 300px) {
                font-size: 0.45rem;
            }

            @media (max-width: 270px) {
                font-size: 0.7rem;
            }

        }

    }

    .App-arrow--down {
        display: none;
    }

    @media (max-width: 270px) {
        font-size: rem;
        flex-direction: column;

        .App-arrow--right {
            display: none;
        }

        .App-arrow--down {
            display: inline;
        }
    }

    @media (max-width: 450px) {
        font-size: 0.6rem;
    }

    p {
        margin: 1rem;

        @media (max-width: 1000px) {
            margin: 0.5rem;
        }

        @media (max-width: 768px) {
            margin: 0.2rem;
        }
    }


}



// .steps-text.active {
//     background: linear-gradient(270deg, var(--color-secondary), var(--color-grey0), blue);
//     background-size: 200% 200%;
//     -webkit-animation: movingGradient 3s ease infinite;
//     -moz-animation: movingGradient 3s ease infinite;
//     animation: movingGradient 3s ease infinite;
//     color: white;
//     /* Adjust text color if needed */
// }

.steps-text.active {
    background: radial-gradient(circle at center, #5927E5, #9B77FF, #7A4FF3);
    background-size: 400% 400%;
    -webkit-animation: movingGradient 5s ease-in-out infinite;
    -moz-animation: movingGradient 5s ease-in-out infinite;
    animation: movingGradient 5s ease-in-out infinite;
    color: white;
    padding: 10px;
    /* Increased padding */
    border-radius: 10px;
    /* Adjust text color if needed */
}