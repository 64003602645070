.image-count-status {


    .image-status-message {
        margin-bottom: 0.2rem;
        text-align: center;
        align-self: center;
        transition: var(--transition-1);
        overflow-wrap: break-word;
        color: var(--color-secondary);
        margin-left: auto;
        margin-right: auto;
        display: block;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 0.8rem;
        }

        @media (max-width: 350px) {
            font-size: 0.7rem;
        }

        @media (max-width: 250px) {
            font-size: 0.6rem;
        }
    }
}