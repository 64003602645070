.cookie-popup {
    position: fixed;
    bottom: 55px;
    right: 20px;
    width: 300px;
    background-color: var(--color-grey-3);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;

    @media (max-width: 1500px) {
        left: 20px;
        right: auto;
    }

    @media (max-width: 1000px) {
        right: 20px;
        left: auto;
    }

    @media (max-width: 850px) {
        bottom: auto;
        top: 85px;
    }

    @media (max-width: 500px) {
        bottom: 5px;
        height: 150px;
        top: auto;
        width: 300px;
        left: auto;
        right: auto;
        padding: 7px;
    }

    @media (max-width: 300px) {
        width: 200px;
    }

    @media (max-width: 200px) {
        width: 150px;
    }

    p {
        margin: 0;
        font-size: 1rem;

        @media (max-width: 500px) {
            font-size: 0.75rem;
        }

        @media (max-width: 300px) {
            font-size: 0.7rem;
        }

        @media (max-width: 200px) {
            font-size: 0.6rem;
        }
    }

    .cookie-buttons {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .generic-account-button {
        text-align: center;
        margin: 0.2rem;
        margin-left: auto;
        margin-right: auto;
        padding: 1.0rem 1.0rem;
        background-color: var(--color-secondary);
        font-size: 1rem;
        font-weight: 400;
        border: 2px solid var(--color-secondary);
        border-radius: 0.5rem;
        color: var(--color-text);
        cursor: pointer;
        align-self: center;
        text-align: center;
        transition: var(--transition-2);
        color: var(--color-button-text);


        @media (max-width: 1420px) {
            padding: 1.0rem 1.0rem;
            font-size: 1.0rem;
        }

        @media (max-width: 1340px) {
            padding: 0.8rem 0.8rem;
            font-size: 0.8rem;
        }

        @media (max-width: 1220px) {
            padding: 0.9rem 0.9rem;
            font-size: 0.7rem;
        }

        @media (max-width: 1180px) {
            padding: 0.6rem 0.6rem;
            font-size: 0.7rem;
        }

        @media (max-width: 1070px) {
            padding: 0.4rem 0.4rem;
            font-size: 0.7rem;
        }


        @media (max-width: 768px) {
            padding: 0.9rem 0.9rem;
            font-size: 1.2rem;
        }

        @media (max-width: 720px) {
            padding: 0.8rem 0.8rem;
            font-size: 1.0rem;
        }

        @media (max-width: 650px) {
            padding: 0.7rem 0.7rem;
            font-size: 0.9rem;
        }

        @media (max-width: 390px) {
            padding: 0.6rem 0.6rem;
            font-size: 0.8rem;
        }

        @media (max-width: 350px) {
            padding: 0.5rem 0.5rem;
            font-size: 0.7rem;
        }

        @media (max-width: 310px) {
            padding: 0.4rem 0.4rem;
            font-size: 0.6rem;
        }

        @media (max-width: 230px) {
            font-size: 0.6rem;
            padding: 0.2rem 0.2rem;
        }

        &:hover {
            background-color: var(--color-brand-icons-hover);
        }

    }

    .reject {
        // Ensure neutral styling not to discourage selection
        background-color: var(--color-grey-3); // Choose a neutral color
        border: 2px solid var(--color-grey-3);
        border-radius: 0.5rem;
        color: var(--color-text);
        font-weight: 300;

        &:hover {
            background-color: var(--color-grey-3); // Slightly darker on hover
            color: var(--color-grey-1);
        }
    }
}