.title-left {
    text-align: left;
    margin: 1.5rem 0;
}

h2 {
    margin: 2rem 0;
    // text-align: left !important;
}

h3 {
    font-size: 1.5rem;
    // text-align: left !important;
}

p {
    margin: 1rem 0;
    font-size: 1rem;
    word-wrap: break-word;
    max-width: 1200px;
}

.text-indented {
    margin-left: 1.5rem;
}

.legal-notice {
    font-size: 0.8rem;
    font-weight: 300;
}

.legal-doc {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
        max-width: 90%;
    }
}